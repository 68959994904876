import React, { useEffect, useState } from 'react';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import loadable, { LoadableLibrary } from '@loadable/component';
import { useParams } from 'react-router-dom';

import {
	AuthService,
	Container,
	ModalService,
	i18n,
} from '../../../../symphony';

import { SlPlaygroundContext } from '../../playground-context';
import { SlPlaygroundActionsService } from '../../services/sl-playground-actions.service';
import { SlPlaygroundCodeService } from '../../services/sl-playground-code.service';
import {
	ActionType,
	IModalStates,
	IModalTypes,
	RequestType,
	SlPlaygroundActionTypes,
	TouchPoint,
} from '../../global-interface';
import { ModalWithoutInput } from './ModalWithoutInput';
import { ModalCodeNaming } from './ModalCodeNaming';
import { ModalForLanguageInputs } from './ModalForLanguageInputs';
import { SlPlaygroundTrackingService } from '../../services/sl-playground-tracking.service';
import { LANGUAGE_TYPES } from '../../constants/LanguageTypes';
import { Actions } from '../../services/ActionsService/actions';
import { ISlLoginResponse } from '../../../../login/private/global.interface';
import { SlModal } from '../../../../shared/public/SlModal/SlModal';
import { TrackingSourceDetail } from '../../../../shared/public/globalInterfaces/trackingInterface';
import { CodeLanguages } from '../../../../api/private/global.interface';

export interface IModalsWrapperProps {
	handleClose?: () => void;
}

export function ModalsWrapper({
	handleClose,
}: IModalsWrapperProps): JSX.Element {
	const [codeNameInputChangeValue, setCodeNameInputChangeValue] = useState<string>('');
	const [inputsValue, setInputsValue] = useState<string>('');
	const [modalData, setModalData] = useState(null);
	const authData = Container.take('global', AuthService).getUser();
	const modalService = Container.take('global', ModalService);
	const { actions$ } = Container.take(
		SlPlaygroundContext,
		SlPlaygroundActionsService,
	);
	const { trackAction$ } = Container.take(
		SlPlaygroundContext,
		SlPlaygroundTrackingService,
	);

	const isTryItYourself: boolean = Container.take(
		SlPlaygroundContext,
		'isTryItYourself',
	);

	const {
		sourceCode$,
		jsCode$,
		cssCode$,
		isPublic$,
		codeData$,
		codeName$,
		inputs$,
		modalData$,
	} = Container.take(SlPlaygroundContext, SlPlaygroundCodeService);

	const isLogged = Container.take('global', AuthService).isLoggedIn();

	const Login: LoadableLibrary<unknown> = loadable.lib(
		() => import(/* webpackPrefetch: true */ '../../../../login/public/SlLogin/SlLogin'),
	);

	const language = useParams()?.language;

	useEffect(() => {
		const unsubscriber = new Subject<void>();

		codeName$
			.pipe(takeUntil(unsubscriber))
			.subscribe(setCodeNameInputChangeValue);

		inputs$.pipe(takeUntil(unsubscriber)).subscribe(setInputsValue);

		modalData$.pipe(takeUntil(unsubscriber)).subscribe((data) => {
			if (data?.state === IModalStates.OPEN) {
				modalService.open('playgroundCodeSavingModal', true);
			} else if (data?.state === 'close') {
				modalService.close('playgroundCodeSavingModal');
			}
			setModalData(data);
		});

		return () => {
			unsubscriber.next();
			unsubscriber.complete();
		};
	}, []);

	const onCompileCode = (value = '') => {
		const languageType = LANGUAGE_TYPES.find(
			(item) => item.language === codeData$.value.language,
		);
		const templateId: string = Container.take(
			SlPlaygroundContext,
			'templateId',
		);
		const quizId: number = Container.take(SlPlaygroundContext, 'quizId');

		if (isTryItYourself) {
			actions$.next({
				type: SlPlaygroundActionTypes.SlTiyRunCall,
			});
		}
		trackAction$.next({
			element: isTryItYourself
				? 'TIY_run_lesson'
				: `codeplayground_usercode_${languageType.path}`,
			action: isTryItYourself ? 'click' : 'run',
			userId: authData?.id.toString(),
			entityId: isTryItYourself ? quizId : codeData$.value?.id,
		});
		actions$.next({
			type: SlPlaygroundActionTypes.compileCode,
			data: {
				code: sourceCode$.value,
				language: codeData$.value?.language,
				input: codeData$.value?.language === CodeLanguages.R
					? `${value}\n`
					: value,
				codeId: templateId,
			},
		});
	};

	const handleSaveCode = (name: string): void => {
		if (!isLogged) {
			actions$.next({
				type: SlPlaygroundActionTypes.openLoginModal,
				requestType: 'parallel',
				data: {
					touchPoint: TouchPoint.Save,
					language,
				},
			});
			return;
		}

		trackAction$.next({
			element: isTryItYourself
				? 'codeplayground_TIY'
				: 'codeplayground_usercode',
			action: 'save',
			entityId: codeData$.value?.id,
			userId: authData?.id.toString(),
		});
		actions$.next({
			type: SlPlaygroundActionTypes.saveUserCode,
			data: {
				sourceCode: sourceCode$.value,
				jsCode: jsCode$.value,
				cssCode: cssCode$.value,
				name,
				isPublic: isPublic$.value,
				language: codeData$.value?.language,
				id: codeData$.value?.id,
			},
		});
	};

	const checkAuthor = () => authData?.id === codeData$.value?.userId;

	const onModalClose = () => {
		modalData$.next({
			...modalData,
			state: IModalStates.CLOSE,
		});
	};

	const onPublishModalClose = () => {
		modalData$.next({
			...modalData,
			state: IModalStates.CLOSE,
		});
		isPublic$.next(codeData$.value?.isPublic || false);
	};

	const closeBtnCallback = () => {
		if (modalData?.type === IModalTypes.PUBLISH) {
			isPublic$.next(codeData$.value?.isPublic || false);
		}

		if (
			modalService.openedModalIds$.value.includes(
				'playgroundLoginModal',
			)
            && modalService.openedModalIds$.value.includes('playgroundCodeSavingModal')
		) {
			modalService.close('playgroundLoginModal');
			return;
		}

		modalData$.next(null);
	};

	const handleModalSaveBtnPress = (inputValue: string) => {
		if (inputValue.trim().length !== 0) {
			handleSaveCode(inputValue);
			onModalClose();
		}
	};

	const handleCloseModalSaveBtnPress = (
		codeNameValue: string = codeName$.value,
	) => {
		if (!isLogged) {
			actions$.next({
				type: SlPlaygroundActionTypes.openLoginModal,
				requestType: 'parallel',
				data: {
					touchPoint: TouchPoint.ChangeName,
					language,
				},
			});
			return;
		}
		if (codeNameValue.trim().length !== 0) {
			handleSaveCode(codeNameValue);
			onModalClose();
		}
	};

	const handleCloseModalDiscardBtnPress = () => {
		handleClose();
		onModalClose();
	};

	const onLanguageInputsSubmitBtnClick = (value: string): void => {
		onCompileCode(value);
		onModalClose();
	};

	const renderModalContent = () => {
		if (modalData?.type === IModalTypes.CLOSE) {
			if (checkAuthor() && codeData$.value.name) {
				return (
					<ModalWithoutInput
						title={i18n.t(
							'web-playground.save-code-modal-title-before-leaving',
						)}
						showDiscardButton
						onDiscardButtonPress={handleCloseModalDiscardBtnPress}
						onSaveBtnPress={() => handleCloseModalSaveBtnPress()}
					/>
				);
			}
			return (
				<ModalCodeNaming
					placeholder="Unnamed"
					title={i18n.t(
						'web-playground.save-code-modal-title-before-leaving',
					)}
					showDiscardButton
					onDiscardButtonPress={handleCloseModalDiscardBtnPress}
					onSaveBtnPress={handleCloseModalSaveBtnPress}
					value={codeNameInputChangeValue}
				/>
			);
		}
		if (modalData?.type === IModalTypes.SAVE) {
			if (
				codeData$.value?.userId
                && codeData$.value?.userId !== authData?.id
			) {
				return (
					<ModalCodeNaming
						placeholder="Unnamed"
						title={i18n.t('web-playground.copy-code-modal-title')}
						showDiscardButton
						onDiscardButtonPress={onModalClose}
						onSaveBtnPress={handleModalSaveBtnPress}
						value={codeNameInputChangeValue}
					/>
				);
			}
			return (
				<ModalCodeNaming
					placeholder="Unnamed"
					title={i18n.t('web-playground.save-code-modal-title')}
					showDiscardButton
					onDiscardButtonPress={onModalClose}
					onSaveBtnPress={handleModalSaveBtnPress}
					value={codeNameInputChangeValue}
				/>
			);
		}
		if (modalData?.type === IModalTypes.RUN) {
			return (
				<ModalForLanguageInputs
					value={inputsValue}
					onSubmitBtnClick={onLanguageInputsSubmitBtnClick}
				/>
			);
		}
		if (modalData?.type === IModalTypes.RENAME) {
			if (
				codeData$.value?.userId
                && codeData$.value?.userId !== authData?.id
			) {
				return (
					<ModalCodeNaming
						placeholder="Unnamed"
						title={i18n.t('web-playground.copy-code-modal-title')}
						showDiscardButton
						onDiscardButtonPress={onModalClose}
						onSaveBtnPress={handleModalSaveBtnPress}
						value={codeNameInputChangeValue}
					/>
				);
			}
			return (
				<ModalCodeNaming
					placeholder="Unnamed"
					title={i18n.t('web-playground.save-code-modal-title')}
					showDiscardButton
					onDiscardButtonPress={onModalClose}
					onSaveBtnPress={handleModalSaveBtnPress}
					value={codeNameInputChangeValue}
				/>
			);
		}
		if (modalData?.type === IModalTypes.PUBLISH) {
			if (
				codeData$.value?.userId
                && codeData$.value?.userId !== authData?.id
			) {
				return (
					<ModalCodeNaming
						placeholder="Unnamed"
						title={i18n.t('web-playground.copy-code-modal-title')}
						showDiscardButton
						onDiscardButtonPress={onPublishModalClose}
						onSaveBtnPress={handleModalSaveBtnPress}
						value={codeNameInputChangeValue}
					/>
				);
			}

			return (
				<ModalCodeNaming
					placeholder="Unnamed"
					title={i18n.t('web-playground.save-code-modal-title')}
					showDiscardButton
					onDiscardButtonPress={onPublishModalClose}
					onSaveBtnPress={handleModalSaveBtnPress}
					value={codeNameInputChangeValue}
				/>
			);
		}
		return null;
	};

	const authResponseHandler = (authResponse: ISlLoginResponse): void => {
		const { actionsV2$ } = Container.take(SlPlaygroundContext, Actions);

		actionsV2$.next({
			requestType: RequestType.Parallel,
			type: ActionType.AuthResponseHandler,
			data: {
				authResponse,
			},
		});

		setModalData({ ...modalData });
	};

	return (
		<>
			<SlModal
				id="playgroundCodeSavingModal"
				closeBtnCallback={closeBtnCallback}
				onOverlay={closeBtnCallback}
			>
				{modalData && renderModalContent()}
			</SlModal>

			<SlModal
				id="playgroundLoginModal"
				closeBtnCallback={closeBtnCallback}
				onOverlay={closeBtnCallback}
				cssClass="playground-login"
			>
				<Login>
					{({ SlLogin }) => (
						<SlLogin
							loginCallback={authResponseHandler}
							defaultRoute="signup"
							useRouter={false}
							redirectTarget="_blank"
							trackingPrefix="playground"
							contextOptions={{
								shouldDefaultRedirect: false,
								trackingEventParams: {
									source: Container.take(
										SlPlaygroundContext,
										'touchPoint',
									),
									sourceDetail:
                                            TrackingSourceDetail.Playground,
								},
							}}
						/>
					)}
				</Login>
			</SlModal>
		</>
	);
}

import React from 'react';
import { useParams } from 'react-router-dom';

import useStyles from 'isomorphic-style-loader/useStyles';
import { AuthService, Container, i18n } from '../../../../../symphony';

import { SlPlaygroundActionTypes } from '../../../global-interface';
import { COURSE_URLS } from '../../../constants/CourseUrls';
import { UserProfile } from '../UserProfile/UserProfile';
import { SlPlaygroundActionsService } from '../../../services/sl-playground-actions.service';
import { SlPlaygroundContext } from '../../../playground-context';

import s from './RightPart.scss';
import { SlLogin } from '../../../../../login/public/SlLogin/SlLogin';
import { useContainerData } from '../../../../../shared/public/SlHooks/useContainerData';
import { LocalizedLink } from '../../../../../shared/public/LocalizedLink/LocalizedLink';
import { LanguageNames } from '../../../../../shared/public/globalInterfaces/globalInterfaces';
import { TrackingSearchParam, TrackingSource, TrackingSourceDetail } from '../../../../../shared/public/globalInterfaces/trackingInterface';

interface IRightPartProps {
	languageKey: string;
}

export function RightPart({ languageKey }: IRightPartProps): JSX.Element {
	useStyles(s);
	const language = useParams()?.language;
	const { userInfo$: userInfo } = useContainerData('global', AuthService, [
		'userInfo$',
	]);

	if (userInfo) {
		return <UserProfile />;
	}

	const { actions$ } = Container.take(
		SlPlaygroundContext,
		SlPlaygroundActionsService,
	);

	const onStartCourseClick = (): void => {
		actions$.next({
			type: SlPlaygroundActionTypes.onStartCourseClick,
			requestType: 'parallel',
			data: {
				language,
			},
		});
	};

	const onRegisterClick = (): void => {
		actions$.next({
			type: SlPlaygroundActionTypes.onRegisterClick,
			requestType: 'parallel',
			data: {
				language,
			},
		});
	};

	return (
		<>
			<SlLogin
				loginType="googleOneTap"
				configs={{
					disableScrollToTop: true,
					isTitleH1: false,
				}}
				useRouter={false}
				contextOptions={{
					shouldDefaultRedirect: false,
				}}
			/>
			<div className="playground-navbar__right-part">
				{COURSE_URLS[language]
                || COURSE_URLS[languageKey?.toLowerCase()] ? (
	<LocalizedLink
							to={
								COURSE_URLS[language]
                            || COURSE_URLS[languageKey?.toLowerCase()]
							}
							onClick={onStartCourseClick}
							target="_blank"
							className="playground-navbar__course-link"
							sl-test-data="lnkStartCourse"
						>
							{i18n.t('web-playground.start-course', {
								courseName:
                                languageKey === 'WEB'
                                	? 'HTML'
                                	: LanguageNames[languageKey],
							})}
						</LocalizedLink>
					) : null}

				<LocalizedLink
					onClick={onRegisterClick}
					to={`/users/signup?${TrackingSearchParam.Source}=${TrackingSource.Register}&${TrackingSearchParam.SourceDetail}=${TrackingSourceDetail.Playground}`}
					className="playground-navbar__register-link"
					sl-test-data="lnkRegisterCourse"
				>
					{i18n.t('web-playground.register-button')}
				</LocalizedLink>
			</div>
		</>
	);
}

import React from 'react';

import useStyles from 'isomorphic-style-loader/useStyles';

import classNames from 'classnames';
import { useContainerData } from '../../../../shared/public/SlHooks/useContainerData';

import s from './Footer.scss';
import { RunButton } from '../../../../shared/public/SlCodeEditorComponents/RunButton/RunButton';
import { SlPlaygroundContext } from '../../playground-context';
import { SlPlaygroundCodeService } from '../../services/sl-playground-code.service';
import { SlPlaygroundActionTypes } from '../../global-interface';
import { SlPlaygroundActionsService } from '../../services/sl-playground-actions.service';
import { Container } from '../../../../symphony';

export const Footer: React.FC = React.memo(() => {
	useStyles(s);

	const {
		isDarkMode$: isDarkMode,
	} = useContainerData(
		SlPlaygroundContext,
		SlPlaygroundCodeService,
		['isDarkMode$'],
	);

	const { actions$ } = Container.take(
		SlPlaygroundContext,
		SlPlaygroundActionsService,
	);

	// const eventService = Container.take(SlPlaygroundContext, SlEventsService);

	// const handleRunBtnClick = (): void => {
	// 	eventService.onHeaderCompileCode();
	// };

	const onRunBtnPress = (): void => {
		actions$.next({
			type: SlPlaygroundActionTypes.onHeaderCompileCode,
		});
	};

	return (
		<div
			className={classNames('sl-playground-footer-wrapper', {
				dark: isDarkMode,
			})}
		>
			<RunButton className="sl-playground-footer-wrapper__button" onClick={onRunBtnPress} />
		</div>
	);
});

import { BehaviorSubject } from 'rxjs';
import { Message } from 'console-feed-optimized/lib/definitions/Console';

import { Service } from '../../../symphony';

import { IFlashMessage, IModalData } from '../global-interface';
import { ActionTypes } from '../constants/ActionTypes';
import {
	ICodeTemplate, IOutputType, IUserCode, LanguagesCatalogType,
} from '../../../api/public/code-api';
import { CodeLanguages } from '../../../api/private/global.interface';
import { LanguagesResponseType } from '../../../api/public/static-pages-api';

export interface IUpdatedCodeTemplate extends ICodeTemplate {
	sourceCode: string;
	name?: string;
	isPublic?: boolean;
	userId?: number;
	avatarUrl?: string;
	userName?: string;
	publicId?: string;
}

export interface IOutput {
	language?: CodeLanguages;
	output: string;
	outputStyle?: {
		dark: string;
		light: string;
	};
	outputType: IOutputType;
}

@Service()
export class SlPlaygroundCodeService {
	public codeData$ = new BehaviorSubject<IUserCode | IUpdatedCodeTemplate>(
		null,
	);

	public sourceCode$ = new BehaviorSubject<string>('');

	public cssCode$ = new BehaviorSubject<string>('');

	public jsCode$ = new BehaviorSubject<string>('');

	public output$ = new BehaviorSubject<IOutput>(null);

	public isWeb$ = new BehaviorSubject<boolean>(false);

	public isPublic$ = new BehaviorSubject<boolean>(false);

	public isDarkMode$ = new BehaviorSubject<boolean>(true);

	public codeName$ = new BehaviorSubject<string>('');

	public inputs$ = new BehaviorSubject<string>('');

	public codeSavingActionType$ = new BehaviorSubject<ActionTypes>(
		ActionTypes.INITIAL,
	);

	public codeCompilingActionType$ = new BehaviorSubject<ActionTypes>(
		ActionTypes.INITIAL,
	);

	public modalData$ = new BehaviorSubject<IModalData>(null);

	public flashMessage$ = new BehaviorSubject<IFlashMessage>(null);

	public topLevelDomain$ = new BehaviorSubject<string>('');

	public showConsole$ = new BehaviorSubject<boolean>(false);

	public outputConsole$ = new BehaviorSubject<Message[]>(null);

	public loading$ = new BehaviorSubject<boolean>(false);

	public isResizingPanel$ = new BehaviorSubject<boolean>(false);

	public showConfirmLeaveAlert$ = new BehaviorSubject<boolean>(false);

	public animationLoader$ = new BehaviorSubject<boolean>(false);

	public freezeLayer$ = new BehaviorSubject<boolean>(false);

	public isUserMenuOpen$ = new BehaviorSubject<boolean>(false);

	public languageCatalog$ = new BehaviorSubject<LanguagesCatalogType>(null);

	public languages$ = new BehaviorSubject<LanguagesResponseType>(null);

	public notificationData$ = new BehaviorSubject<IFlashMessage>(null);
}

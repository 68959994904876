import { Observable, of } from 'rxjs';
import {
	AuthService,
	Container,
	ModalService,
	Service,
	TrackingEventNames,
} from '../../../../symphony';
import { SlPlaygroundContext } from '../../playground-context';
import { Actions } from '../ActionsService/actions';
import {
	ActionType,
	CatalogLanguageItemClickType,
	IActionData,
	NavigationClickType,
	RequestType,
	TouchPoint,
	TrackEventName,
} from '../../global-interface';
import { SlPlaygroundCodeService } from '../sl-playground-code.service';
import { LANGUAGE_TYPES } from '../../constants/LanguageTypes';
import { LanguageRoutes } from '../../../../shared/public/globalInterfaces/globalInterfaces';
import { CodeLanguages } from '../../../../api/private/global.interface';

@Service()
export class EventsService {
	private actionsV2$ = Container.take(SlPlaygroundContext, Actions)
		.actionsV2$;

	private authService = Container.take('global', AuthService);

	private modalService = Container.take('global', ModalService);

	private dataService = Container.take(
		SlPlaygroundContext,
		SlPlaygroundCodeService,
	);

	public languageTypesMountHandler = () => {
		this.actionsV2$.next({
			type: ActionType.Track,
			requestType: RequestType.Parallel,
			data: {
				trackData: {
					eventName:
                        TrackingEventNames.compilerLanguagesCatalogImpression,
					eventParams: {
						pageAlias: 'COMPILER CATALOG',
					},
				},
			},
		});

		this.actionsV2$.next({
			type: ActionType.GetLanguagesCatalog,
			requestType: RequestType.Parallel,
		});

		this.actionsV2$.next({
			type: ActionType.GetLanguages,
			requestType: RequestType.Parallel,
		});

		return of(null);
	};

	public languageItemClickHandler = (
		programLanguage: string,
		clickType: CatalogLanguageItemClickType,
		section: string,
	) => {
		this.actionsV2$.next({
			type: ActionType.Track,
			requestType: RequestType.Parallel,
			data: {
				trackData: {
					eventName: TrackingEventNames.compilerLanguagesCatalogClick,
					eventParams: {
						pageAlias: 'CATALOG',
						programLanguage,
						clickType,
						section,
					},
				},
			},
		});
		return of(null);
	};

	public onPositiveReactionClick = ({
		language,
	}: IActionData): Observable<null> => {
		if (this.authService.isLoggedIn()) {
			this.actionsV2$.next({
				type: ActionType.UpdatePositiveReaction,
				requestType: RequestType.Parallel,
			});
		} else {
			this.openLoginModal({ language, touchPoint: TouchPoint.VoteCode });
		}

		return of(null);
	};

	private openLoginModal = ({
		touchPoint,
		language,
	}: {
		touchPoint: TouchPoint;
		language: CodeLanguages;
	}) => {
		this.modalService.open('playgroundLoginModal', true);
		Container.register(SlPlaygroundContext, 'touchPoint', touchPoint);
		this.trackHandler(TrackEventName.InitSignUp, {
			touchPoint,
			language,
		});
	};

	private trackHandler = (
		eventName: TrackEventName,
		options: {
			touchPoint?: TouchPoint;
			language?: CodeLanguages;
			clickType?: NavigationClickType;
		} = {},
	) => {
		const codeService = Container.take(
			SlPlaygroundContext,
			SlPlaygroundCodeService,
		);
		const data: {
			touchpoint?: TouchPoint;
			program_language?: LanguageRoutes;
			click_type?: NavigationClickType;
		} = {};

		if (eventName === TrackEventName.InitSignUp) {
			data.touchpoint = options.touchPoint;
		}

		if (eventName === TrackEventName.NavigationClick) {
			data.click_type = options.clickType;
		}

		const language = codeService.codeData$.value?.language;
		data.program_language = LANGUAGE_TYPES.find((item) => {
			if (item.language === options.language) {
				return true;
			}
			if (
				language === CodeLanguages.HTML
                || language === CodeLanguages.CSS
                || language === CodeLanguages.JS
			) {
				return item.language === CodeLanguages.WEB;
			}

			return item.language === language;
		})?.path;

		// Todo send web instead of javascript (bug) /compiler/javascript
		// Remove this code after make a change the schemas about javascript programming language
		if (
		// @ts-ignore
			(options.language === 'javascript'
                && data.program_language === LanguageRoutes.WEB)
            || data.program_language === LanguageRoutes.JAVASCRIPT
		) {
			// @ts-ignore
			data.program_language = 'js';
		}

		this.actionsV2$.next({
			type: ActionType.Track,
			requestType: RequestType.Parallel,
			data: {
				trackData: {
					eventName,
					eventParams: {
						...data,
						code_id: codeService.codeData$.value?.publicId || '',
					},
				},
			},
		});
	};

	public triggerNotificationVisibility = (): Observable<null> => {
		this.dataService.notificationData$.next(null);
		return of(null);
	};
}

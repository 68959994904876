import * as esprima from 'esprima';

export function infiniteLoopProtectInstrument(code: string): string {
	const LOOP_CHECK = 'if (window.SL.shouldStopExecution(%d)){break;}';
	const LOOP_EXIT = '\nwindow.SL.exitedLoop(%d);\n';

	let loopId = 1;
	const patches = [];
	let sourceCode = code;

	esprima.parse(
		sourceCode,
		{
			range: true,
			tolerant: false,
			sourceType: 'script',
			jsx: true,
		},
		(node) => {
			switch (node.type) {
			case 'DoWhileStatement':
			case 'ForStatement':
			case 'ForInStatement':
			case 'ForOfStatement':
			case 'WhileStatement': {
				let start = 1 + node.body.range[0];
				const end = node.body.range[1];
				let prolog = LOOP_CHECK.replace('%d', loopId.toString());
				let epilog = '';
				if (node.body.type !== 'BlockStatement') {
					// `while(1) doThat()` becomes `while(1) {doThat()}`
					prolog = `{${prolog}`;
					epilog = '}';
					--start;
				}

				patches.push({ pos: start, str: prolog });
				patches.push({ pos: end, str: epilog });
				patches.push({
					pos: node.range[1],
					str: LOOP_EXIT.replace('%d', loopId.toString()),
				});
				++loopId;
				break;
			}

			default:
				break;
			}
		},
	);

	patches
		.sort((a, b) => b.pos - a.pos)
		.forEach((patch) => {
			sourceCode = sourceCode.slice(0, patch.pos)
                + patch.str
                + sourceCode.slice(patch.pos);
		});

	return sourceCode;
}

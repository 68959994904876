import React from 'react';
import { IconCrossSmall, IconEditSmall } from '@sololearnorg/sol';
import useStyles from 'isomorphic-style-loader/useStyles';
import { UserInfo } from './UserInfo';
import { VoteCode } from './VoteCode';
import s from './LeftSide.scss';
import { i18n } from '../../../../../symphony';

export interface IHeaderLeftSideProps {
	author: {
		avatarUrl: string;
		name: string;
	};
	title: string;
	onCloseIcon: () => void;
	onRenameBtnPress: () => void;
	isTryItYourself: boolean;
	isDarkMode?: boolean;
	getUserInfoHref: () => string;
}

export const HeaderLeftSide: React.FC<IHeaderLeftSideProps> = React.memo(
	({
		author,
		title,
		onCloseIcon,
		onRenameBtnPress,
		isTryItYourself,
		isDarkMode,
		getUserInfoHref,
	}) => {
		useStyles(s);
		const getEmptyStateTitle = () => i18n.t(isTryItYourself ? 'code_playground.try-it-yourself.title' : 'code_playground.empty-playground.title');

		return (
			<div className="sl-playground-left">
				<div
					style={{
						color: isDarkMode ? '#c8d2db' : '#2d3846',
					}}
					onClick={onCloseIcon}
					className="sl-playground-left__close-icon"
				>
					<IconCrossSmall size={18} />
				</div>
				<div
					className="sl-playground-left__name-wrapper"
					onClick={
						!title && isTryItYourself ? null : onRenameBtnPress
					}
				>
					<h2
						style={{
							color: isDarkMode ? '#c8d2db' : '#2d3846',
						}}
						className="sl-playground-left__code-title"
					>
						{!title ? getEmptyStateTitle() : title}
					</h2>
					{(!isTryItYourself || title) && (
						<div className="sl-playground-left__pencil-icon">
							<IconEditSmall size={18} />
						</div>
					)}
				</div>
				<VoteCode isDarkMode={isDarkMode} />

				{author?.name && (
					<UserInfo
						avatarUrl={author.avatarUrl}
						authorName={author.name}
						isDarkMode={isDarkMode}
						href={getUserInfoHref()}
					/>
				)}
			</div>
		);
	},
);

import React, { useEffect, useState } from 'react';
import { IconReactionPositiveSmall } from '@sololearnorg/sol';
import { useParams } from 'react-router-dom';
import { Container } from '../../../../../symphony';
import { SlPlaygroundContext } from '../../../playground-context';
import { Actions } from '../../../services/ActionsService/actions';
import { ActionType, RequestType } from '../../../global-interface';
import { SlPlaygroundCodeService } from '../../../services/sl-playground-code.service';
import { useContainerData } from '../../../../../shared/public/SlHooks/useContainerData';
import { LanguageRoutes } from '../../../../../shared/public/globalInterfaces/globalInterfaces';
import { CodeLanguages } from '../../../../../api/private/global.interface';

export interface IVoteCodeProps {
	isDarkMode?: boolean;
}

export function VoteCode({ isDarkMode }: IVoteCodeProps): JSX.Element {
	const { codeData$: codeData } = useContainerData(
		SlPlaygroundContext,
		SlPlaygroundCodeService,
		['codeData$'],
	);
	const { language } = useParams();
	const [languageState, setLanguageState] = useState<string>(language);

	useEffect(() => {
		if (codeData?.publicId) {
			setLanguageState(codeData.publicId);
		}
	}, [codeData]);

	const isNotUserCode = (Object.values(LanguageRoutes) as string[]).includes(
		languageState,
	);
	if (isNotUserCode) {
		return null;
	}

	const positiveReactionClickHandler = () => {
		const { actionsV2$ } = Container.take(SlPlaygroundContext, Actions);
		actionsV2$.next({
			type: ActionType.OnPositiveReactionClick,
			requestType: RequestType.Parallel,
			data: {
				language: language as CodeLanguages,
			},
		});
	};

	const getVoteCount = () => {
		const voteCount = codeData?.votes;
		const voteCountABS = Math.abs(voteCount);

		if (voteCount > 0) {
			return `+${voteCountABS}`;
		}

		if (voteCount < 0) {
			return `-${voteCountABS}`;
		}

		return 0;
	};

	return (
		<div
			className={`sl-playground-left__vote${
				isDarkMode ? ' dark' : ' light'
			}${codeData?.vote === 1 ? ' voted' : ''}`}
		>
			<IconReactionPositiveSmall onClick={positiveReactionClickHandler} />
			<span className="sl-playground-left__vote-count">
				{getVoteCount()}
			</span>
		</div>
	);
}

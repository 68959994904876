import React, { useEffect, useRef } from 'react';
import { Route, Routes } from 'react-router-dom';
import { takeUntil } from 'rxjs/operators';
import { merge, Subject } from 'rxjs';

import useStyles from 'isomorphic-style-loader/useStyles';
import { Container } from '../../../symphony';

import { LanguageTypes } from '../../private/containers/LanguageTypes/LanguageTypes';
import { useTopLevelDomain } from '../../private/components/HOOKS/useTopLevelDomain';
import { SlPlaygroundActionsService } from '../../private/services/sl-playground-actions.service';
import { SlPlaygroundTrackingService } from '../../private/services/sl-playground-tracking.service';
import { PlaygroundContainer } from '../../private/containers/PlaygroundContainer/PlaygroundContainer';
import { SlPlaygroundActionTypes } from '../../private/global-interface';
import { SlPlaygroundContext } from '../../private/playground-context';
import { ActionsService } from '../../private/services/ActionsService/actions.service';

import s from './SlPlaygroundEditor.scss';
import { useComponentWillMount } from '../../../shared/public/SlUtils/useComponentWillMount';
import { ICodeVersions } from '../../../api/public/code-api';

export interface SlPlaygroundEditorProps {
	configs: {
		playgroundApiHost: string;
		codeVersions?: ICodeVersions;
		useMock: boolean;
		soloLearnUrl?: string;
	};
}

export function SlPlaygroundEditor({
	configs,
}: SlPlaygroundEditorProps): JSX.Element {
	useStyles(s);
	const topLevelDomain = useTopLevelDomain();
	const unsubscriber = useRef(new Subject<void>());

	useComponentWillMount(() => {
		const registerConfig = {
			...configs,
			isTryItYourself: false,
			templateId: null,
			soloLearnUrl: configs.soloLearnUrl,
			courseId: null,
			quizId: null,
			touchPoint: null,
		};

		Object.keys(registerConfig).forEach((item) => {
			Container.register(SlPlaygroundContext, item, registerConfig[item]);
		});

		const { actions$ } = Container.take(
			SlPlaygroundContext,
			SlPlaygroundActionsService,
		);

		const { trackActionHandler$, tagManagerHandler$ } = Container.take(
			SlPlaygroundContext,
			SlPlaygroundTrackingService,
		);

		actions$.next({
			type: SlPlaygroundActionTypes.initApp,
			data: {
				topLevelDomain,
			},
			requestType: 'parallel',
		});

		merge(actions$, trackActionHandler$, tagManagerHandler$)
			.pipe(takeUntil(unsubscriber.current))
			.subscribe();
		Container.register(
			SlPlaygroundContext,
			'unsubscriber',
			unsubscriber.current,
		);
		Container.take(SlPlaygroundContext, ActionsService);
	});

	useEffect(() => () => {
		unsubscriber.current.next();
		unsubscriber.current.complete();
		Container.reset(SlPlaygroundContext);
	}, []);

	return (
		<div className="sl-playground-wrapper">
			<Routes>
				<Route element={<LanguageTypes />} path="*" />
				<Route element={<PlaygroundContainer />} path=":language/*" />
			</Routes>
		</div>
	);
}

import React, { useEffect, useState } from 'react';
import { takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import useStyles from 'isomorphic-style-loader/useStyles';

import { Container } from '../../../../symphony';
import { SlPlaygroundContext } from '../../playground-context';
import { SlPlaygroundCodeService } from '../../services/sl-playground-code.service';

import s from './FreezeLayer.scss';

export function FreezeLayer(): JSX.Element {
	useStyles(s);
	const [isVisible, setIsVisible] = useState<boolean>(false);

	useEffect(() => {
		const unsubscriber = new Subject<void>();

		const { freezeLayer$ } = Container.take(
			SlPlaygroundContext,
			SlPlaygroundCodeService,
		);
		freezeLayer$
			.pipe(takeUntil(unsubscriber), tap(setIsVisible))
			.subscribe();

		return () => {
			unsubscriber.next();
			unsubscriber.complete();
		};
	}, []);

	return isVisible ? <div className="freeze-layer" /> : null;
}

import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { SlNavBarPositions, SlNavbarThemes } from '../../../features/shared/public/globalInterfaces/globalInterfaces';
import { SlNavbarState } from '../../../features/navigation/private/global-interface';
import { SlPlaygroundEditor } from '../../../features/playground/public/SlPlaygroundEditor/SlPlaygroundEditor';
import { ApiUrls } from '../../shared/api-urls';
import { codeVersions } from '../../shared/api-versions';
import { setNavigationConfigs } from '../../utils/navigationManager';

const configs = {
	playgroundApiHost: ApiUrls.playgroundApiHost,
	useMock: false,
	codeVersions,
	soloLearnUrl: ApiUrls.soloLearnUrl,
};

export const Playground = React.memo(() => {
	const { pathname } = useLocation();

	useEffect(() => {
		if (pathname === '/compiler-playground' || pathname === '/compiler-playground/') {
			setNavigationConfigs({
				state: SlNavbarState.shown,
				theme: SlNavbarThemes.light,
				position: SlNavBarPositions.relative,
			});

			return () => {
				setNavigationConfigs({
					state: SlNavbarState.hidden,
				});
			};
		}

		return () => {
			setNavigationConfigs({
				state: SlNavbarState.hidden,
			});
		};
	}, [pathname]);

	return <SlPlaygroundEditor configs={configs} />;
});

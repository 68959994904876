import React from 'react';

import useStyles from 'isomorphic-style-loader/useStyles';
import { i18n } from '../../../../../symphony';

import { HeaderSaveBtn } from './SaveBtn';
import { ToggleBtn } from '../../../../../shared/public/SlCodeEditorComponents/ToggleBtn/ToggleBtn';

import s from './RightSide.scss';
import { CodeLanguages } from '../../../../../api/private/global.interface';

export interface IHeaderRightSideProps {
	onSaveBtnClick: () => void;
	onChangePublicState: (state: boolean) => void;
	onChangeEditorMode: (state: boolean) => void;
	isPublic: boolean;
	isDarkMode: boolean;
	language: CodeLanguages;
}

export function HeaderRightSide({
	onSaveBtnClick,
	onChangePublicState,
	onChangeEditorMode,
	isPublic,
	isDarkMode,
	language,
}: IHeaderRightSideProps): JSX.Element {
	useStyles(s);

	return (
		<div className="sl-header-right-wrapper">
			<ToggleBtn
				state={isDarkMode}
				onChange={onChangeEditorMode}
				title={i18n.t('web-playground.dark-toggle')}
				isDarkMode={isDarkMode}
			/>
			{language !== CodeLanguages.SQL && (
				<>
					<ToggleBtn
						onChange={onChangePublicState}
						state={isPublic}
						isDarkMode={isDarkMode}
						title={i18n.t('web-playground.public-toggle')}
					/>
					<div className="sl-header-right-wrapper__margins">
						<HeaderSaveBtn
							isDarkMode={isDarkMode}
							onClick={onSaveBtnClick}
							title={i18n.t('web-playground.save-button')}
						/>
					</div>
				</>
			)}
		</div>
	);
}

import { merge, of, Subject } from 'rxjs';
import {
	filter, mergeMap, switchMap, takeUntil,
} from 'rxjs/operators';

import { Container, Service } from '../../../../symphony';

import { Actions } from './actions';
import { ActionsController } from './actions.controller';
import { ActionType, IAction, RequestType } from '../../global-interface';
import { SlPlaygroundContext } from '../../playground-context';

@Service()
export class ActionsService {
	private actions$ = Container.take(SlPlaygroundContext, Actions).actionsV2$;

	private controller = Container.take(SlPlaygroundContext, ActionsController);

	private cancelableActionsMap = {
		// Network communication
		// Business actions
	};

	private parallelActionsMap = {
		// Network communication
		// Business actions
		// Component Events
		[ActionType.OnLanguageTypesMount]:
            this.controller.onLanguageTypesMountHandler,

		// Click events
		[ActionType.OnLanguageItemClick]:
            this.controller.onLanguageItemClickHandler,
		[ActionType.OnPositiveReactionClick]:
            this.controller.onPositiveReactionClickHandler,

		// Callback events
		[ActionType.AuthResponseHandler]: this.controller.authResponseHandler,

		// Track Actions
		[ActionType.Track]: this.controller.trackHandler,

		// Network Actions
		[ActionType.GetLanguagesCatalog]:
            this.controller.getLanguagesCatalogHandler,
		[ActionType.GetLanguages]: this.controller.getLanguagesHandler,
		[ActionType.UpdatePositiveReaction]:
            this.controller.updatePositiveReactionHandler,

		// Business actions
		[ActionType.TriggerNotificationVisibility]:
            this.controller.triggerNotificationVisibilityHandler,
	};

	constructor() {
		const unsubscriber: Subject<void> = Container.take(
			SlPlaygroundContext,
			'unsubscriber',
		);

		const cancelable = this.actions$.pipe(
			filter((a) => !!a && a.requestType === RequestType.Cancelable),
			switchMap((action: IAction) => {
				const handler = this.cancelableActionsMap[action.type];
				return handler ? handler(action.data) : of(action);
			}),
		);

		const parallel = this.actions$.pipe(
			filter((a) => !!a && a.requestType === RequestType.Parallel),
			mergeMap((action: IAction) => {
				const handler = this.parallelActionsMap[action.type];
				return handler ? handler(action.data) : of(action);
			}),
		);

		merge(cancelable, parallel)
			.pipe(
				filter((a) => !!a),
				takeUntil(unsubscriber),
			)
			.subscribe();
	}
}

import React, { useRef, useState } from 'react';

import { Button } from '@sololearnorg/sol';
import useStyles from 'isomorphic-style-loader/useStyles';

import { i18n } from '../../../../symphony';
import s from './Modals.scss';

export interface IModalForLanguageInputsProps {
	value?: string;
	onSubmitBtnClick?: (value: string) => void;
}

export function ModalForLanguageInputs({
	value,
	onSubmitBtnClick,
}: IModalForLanguageInputsProps): JSX.Element {
	useStyles(s);
	const textareaRef = useRef<HTMLTextAreaElement>(null);
	const [inputsValue, setInputsValue] = useState<string>(value);

	const onInputChange = (input: string): void => {
		setInputsValue(input);
	};

	const handleSubmitBtnPress = () => {
		onSubmitBtnClick(inputsValue);
	};
	return (
		<div className="sl-modal-language-inputs" onKeyDown={(e) => e.stopPropagation()}>
			<h3 className="sl-modal-language-inputs__title">
				{i18n.t('code.input.title')}
			</h3>
			<p className="sl-modal-language-inputs__sub-title">{i18n.t('code.input.subtitle')}</p>
			<textarea
				onInput={(e: any) => {
					e.target.style.height = '78px';
					e.target.style.height = `${e.target.scrollHeight + 2}px`;
				}}
				maxLength={100}
				ref={textareaRef}
				className="sl-modal-language-inputs__input"
				value={inputsValue}
				onChange={(event) => onInputChange(event.target.value)}
			/>
			<span className="sl-modal-language-inputs__input-length">
				{inputsValue.length}
				/100
			</span>
			<Button
				disabled={!inputsValue.length}
				onClick={handleSubmitBtnPress}
			>
				{i18n.t('common.submit-action-title')}
			</Button>
		</div>
	);
}

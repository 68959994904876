import { RefObject } from 'react';
import { i18n } from '../../../symphony';
import { InputRegexes } from '../constants/Regexps';
import { getTransformedJsCode } from './codeTransformation';
import {
	windowOnErrorSetter,
	windowLoopProtectionHandler,
} from './externalScripts/externalScripts';
import { BACKEND_ERROR_MESSAGES } from '../global-constants';
import { CodeLanguages } from '../../../api/private/global.interface';

export const checkForInputs = (
	language: CodeLanguages,
	code: string,
): boolean => {
	if (!InputRegexes[language]) {
		return false;
	}
	const regExp = new RegExp(InputRegexes[language], 'g');
	let codeBlock = code;

	if (language === CodeLanguages.PYTHON) {
		codeBlock = code.replace(/(([^'"])(#)|(^#))((.*)$)/gm, ''); // $2
	} else if (language === CodeLanguages.RUBY) {
		codeBlock = code.replace(
			/(=begin(\n[\s\S]*?)=end)|(([^'"])(#)|(^#))((.*)$)/gm,
			'',
		);
	} else {
		codeBlock = code.replace(/(\/\*([\s\S]*?)\*\/)|(\/\/(.*)$)/gm, '');
	}

	return regExp.test(codeBlock);
};

export const getInitialDate = (): string => {
	const date = new Date();
	return `${String(date.getMonth() + 1).padStart(2, '0')}/${String(
		date.getDate(),
	).padStart(2, '0')}/${date.getFullYear()} ${date.getHours()}:${String(
		date.getMinutes(),
	).padStart(2, '0')}`;
};

export const wrapByTag = (code: string, tag: string): string => {
	const hasTag = code ? code.includes(tag) : false;
	if (!hasTag) {
		return `<${tag}>
						${code || ''}
</${tag}>`;
	}
	return code;
};

function functionToString(func): string {
	return `${func.toString().match(/function[^{]+\{([\s\S]*)\}$/)[1]};`;
}

export const getStructuredWebCode = (
	sourceCode = '',
	cssCode = '',
	jsCode = '',
): string => {
	const localSourceCode = sourceCode.replace(/<!--(?!>)[\S\s]*?-->/g, '');
	let htmlCode = wrapByTag(localSourceCode, 'html');
	let css = '';
	let js = '';
	const externalScript = `<script>${functionToString(
		windowOnErrorSetter,
	)}${functionToString(windowLoopProtectionHandler)}</script>`;
	if (cssCode && cssCode.trim()) {
		css = `<style>${cssCode} </style>`;
	}
	if (jsCode && jsCode.trim()) {
		js = `<script>${getTransformedJsCode(jsCode)}</script>`;
	}
	const includeBody = localSourceCode
		? localSourceCode.includes('<body>')
		: false;
	const includeHead = localSourceCode
		? localSourceCode.includes('<head>')
		: false;
	const htmlTagIndex = htmlCode.indexOf('<html>') + 6;

	if (!includeHead) {
		htmlCode = [
			htmlCode.slice(0, htmlTagIndex),
			`<head>${externalScript}${css}</head>`,
			htmlCode.slice(htmlTagIndex),
		].join('');
	} else {
		const closeHeadTagIndex = htmlCode.indexOf('<head>') + 6;
		htmlCode = [
			htmlCode.slice(0, closeHeadTagIndex),
			`${externalScript}${css}`,
			htmlCode.slice(closeHeadTagIndex),
		].join('');
	}
	if (!includeBody) {
		const closeHtmlTagIndex = htmlCode.indexOf('</html>');
		htmlCode = [
			htmlCode.slice(0, closeHtmlTagIndex),
			`<body>${js}</body>`,
			htmlCode.slice(closeHtmlTagIndex),
		].join('');
	} else {
		const closeBodyTagIndex = htmlCode.indexOf('</body>');
		htmlCode = [
			htmlCode.slice(0, closeBodyTagIndex),
			js,
			htmlCode.slice(closeBodyTagIndex),
		].join('');
	}

	return htmlCode;
};

export const setSuccessDialogClass = (
	successDialogRef: RefObject<HTMLDivElement>,
	tailWidth: string,
): void => {
	if (successDialogRef?.current) {
		// eslint-disable-next-line no-param-reassign
		successDialogRef.current.style.width = `calc(100% - ${tailWidth})`;
		const clientWidth: number = successDialogRef.current.clientWidth;
		const cName = 'sl-success-dialog';
		let tail = '';
		if (clientWidth < 200) {
			tail = '__xs';
		} else if (clientWidth < 350) {
			tail = '__sm';
		} else if (clientWidth < 478) {
			tail = '__md';
		} else {
			tail = '';
		}
		// eslint-disable-next-line no-param-reassign
		successDialogRef.current.className = `${cName}${
			tail ? ` ${cName}${tail}` : ''
		}`;
	}
};

export const getInitialStateForWeb = (
	language: CodeLanguages = null,
): {
	initialCollapsesPanels?: { [key: string]: boolean };
	initialPanelWith?: string[];
} => {
	if (language === CodeLanguages.HTML) {
		return {
			initialCollapsesPanels: {
				1: true,
				2: true,
			},
			initialPanelWith: ['50%', '25%', '25%', '25%'],
		};
	}

	if (language === CodeLanguages.CSS) {
		return {
			initialCollapsesPanels: {
				0: true,
				2: true,
			},
			initialPanelWith: ['25%', '50%', '25%', '50%'],
		};
	}
	if (language === CodeLanguages.JS) {
		return {
			initialCollapsesPanels: {
				0: true,
				1: true,
			},
			initialPanelWith: ['25%', '25%', '50%', '50%'],
		};
	}
	return {
		initialCollapsesPanels: {},
		initialPanelWith: ['25%', '25%', '25%', '25%'],
	};
};

export const getBackendErrorMessage = (errorCode: number) => (
	i18n.t(BACKEND_ERROR_MESSAGES[errorCode])
        || i18n.t('web-playground.something-went-wrong')
);

import React, {
	useEffect, useState, memo, useMemo,
} from 'react';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Message } from 'console-feed-optimized/lib/definitions/Console';
import { Variants } from 'console-feed-optimized/lib/definitions/Component';
import { Console } from 'console-feed-optimized';

import useStyles from 'isomorphic-style-loader/useStyles';
import { Container } from '../../../../../symphony';

import { SlPlaygroundCodeService } from '../../../services/sl-playground-code.service';
import { SlPlaygroundContext } from '../../../playground-context';

import s from './OutputConsole.scss';
import { CONSOLE_STYLES } from '../../../../../shared/public/SlCodeEditorComponents/types/types';

export interface OutputConsoleProps {
	isDarkMode?: boolean;
}

export const OutputConsole: React.FC<OutputConsoleProps> = memo(
	({ isDarkMode = true }) => {
		useStyles(s);
		const [outputConsole, setOutputConsole] = useState<Message[]>(null);

		useEffect(() => {
			const unsubscriber = new Subject<void>();

			const { outputConsole$ } = Container.take(
				SlPlaygroundContext,
				SlPlaygroundCodeService,
			);

			outputConsole$
				.pipe(takeUntil(unsubscriber))
				.subscribe(setOutputConsole);

			return () => {
				unsubscriber.next();
				unsubscriber.complete();
			};
		}, []);

		const style = useMemo(() => {
			if (isDarkMode) {
				return {
					mode: 'dark',
					output: CONSOLE_STYLES.DARK,
				};
			}
			return {
				mode: 'light',
				output: CONSOLE_STYLES.LIGHT,
			};
		}, [isDarkMode]);

		return (
			<div className={`sl-console sl-console__${style.mode}`}>
				<div className="sl-console__title">Console</div>
				<div className="sl-console__output">
					<div
						className={`sl-console__output__items sl-console__output__items__${style.mode}`}
					>
						{outputConsole && (
							<Console
								logs={outputConsole as any}
								variant={style.mode as Variants}
								styles={style.output}
							/>
						)}
					</div>
				</div>
			</div>
		);
	},
);

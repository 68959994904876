import React, {
	useEffect, useState, useCallback, useMemo,
} from 'react';
import { merge, Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

import useStyles from 'isomorphic-style-loader/useStyles';
import { Container } from '../../../../symphony';

import { SlPlaygroundContext } from '../../playground-context';
import {
	IOutput,
	SlPlaygroundCodeService,
} from '../../services/sl-playground-code.service';
import { ILanguageType } from '../../constants/LanguageTypes';
import { OutputIframeContainer } from '../../components/Output/OutputIframeContainer';
import { EditorWithLabel } from '../../components/EditorWithLabel/EditorWithLabel';
import { OutputTextContainer } from '../../components/Output/OutputTextContainer';
import CodeLoader from '../../components/Loader/CodeLoader/CodeLoader';

import s from './NotWeb.scss';
import { ICollapsedPanels, SplitPane } from '../../../../shared/public/SlCodeEditorComponents/SplitPane/SplitPane';
import { Orientation } from '../../../../shared/public/SlCodeEditorComponents/types/types';
import { Panel } from '../../../../shared/public/SlCodeEditorComponents/Panel/Panel';
import { CollapsedView } from '../../../../shared/public/SlCodeEditorComponents/CollapsedView/CollapsedView';
import { IOutputType } from '../../../../api/public/code-api';
import { CodeLanguages } from '../../../../api/private/global.interface';
import { OutputHeader } from '../../../../shared/public/SlCodeEditorComponents/OutputHeader/OutputHeader';

export interface INotWebProps {
	languageType: ILanguageType;
}

export function NotWeb({ languageType }: INotWebProps): JSX.Element {
	useStyles(s);
	const [sourceCode, setSourceCode] = useState<string>(null);
	const [output, setOutput] = useState<IOutput>(null);
	const [isDarkMode, setIsDarkMode] = useState<boolean>(true);
	const [collapsedPanels, setCollapsedPanels] = useState<ICollapsedPanels>(
		{},
	);
	const [collapsesStates, setCollapsesStates] = useState({});
	const [isResizing, setIsResizing] = useState<boolean>(false);

	const {
		sourceCode$,
		showConfirmLeaveAlert$,
		isResizingPanel$
	} = Container.take(SlPlaygroundContext, SlPlaygroundCodeService);

	useEffect(() => {
		const { codeData$, output$, isDarkMode$ } = Container.take(
			SlPlaygroundContext,
			SlPlaygroundCodeService,
		);
		const unsubscriber = new Subject<void>();

		merge(
			codeData$.pipe(
				filter((d) => !!d),
				tap((data) => {
					setSourceCode(data.sourceCode);
				}),
			),
			output$.pipe(tap(setOutput)),
			isDarkMode$.pipe(tap(setIsDarkMode)),
			isResizingPanel$.pipe(tap(setIsResizing)),
		)
			.pipe(takeUntil(unsubscriber))
			.subscribe();

		return () => {
			unsubscriber.next();
			unsubscriber.complete();
		};
	}, []);

	const onChange = (value) => {
		if (!showConfirmLeaveAlert$.value) {
			showConfirmLeaveAlert$.next(true);
		}
		sourceCode$.next(value);
	};

	const handleResize = useCallback(
		(sizes) => {
			let mergeState = collapsesStates;
			let collapseStateChange = false;
			const MIN_WIDTH = 48;

			sizes.forEach((size) => {
				if (size.width <= MIN_WIDTH) {
					if (!mergeState[size.id]) {
						collapseStateChange = true;
						mergeState = {
							...mergeState,
							[size.id]: true,
							size,
						};
					}
					return null;
				}
				if (size.width > MIN_WIDTH) {
					if (mergeState[size.id]) {
						collapseStateChange = true;
						mergeState = {
							...mergeState,
							[size.id]: false,
							size,
						};
					}
				}
				return null;
			});
			if (collapseStateChange) {
				setCollapsesStates(mergeState);
			}
		},
		[collapsesStates],
	);

	const onMouseDown = (): void => {
		isResizingPanel$.next(true);
	};

	const onMouseUp = (): void => {
		isResizingPanel$.next(false);
	};

	const onCollapseClick = (id: string, orientation: Orientation) => (): void => {
		setCollapsedPanels({
			state: true,
			currentId: id,
			[id]: true,
			orientation,
		});
		setCollapsesStates({});
	};

	const callbackCollapseClicked = (id) => {
		setCollapsedPanels({
			state: false,
			currentId: id,
			[id]: false,
			orientation: 'horizontal',
		});
	};

	const isVisibleOutFrame = useMemo(() => (
		output?.outputType === IOutputType.html
		|| languageType?.language === CodeLanguages.SQL
	), [output]);

	return (
		<SplitPane
			horizontal
			onResize={handleResize}
			onMouseUp={onMouseUp}
			onMouseDown={onMouseDown}
			collapsedPanels={collapsedPanels}
			callbackCollapseClicked={callbackCollapseClicked}
			defaultWidth={50}
			className="split-pane__horizontal"
		>
			<Panel width="50%" isResizing={isResizing}>
				<div className="panel-wrapper">
					<CollapsedView
						isDarkMode={isDarkMode}
						title={languageType?.label}
						show={collapsesStates['0']}
						onClick={onCollapseClick('0', 'horizontal')}
					/>
					{
						sourceCode === null ? null : <EditorWithLabel
							language={languageType?.language}
							value={sourceCode}
							onChange={onChange}
							isDarkMode={isDarkMode}
						/>
					}
					<CodeLoader />
				</div>
			</Panel>
			<Panel width="50%" isResizing={isResizing}>
				<div className="sl-not-web-wrapper__output">
					<OutputHeader title="OUTPUT" />
					<div
						className={`sl-not-web-wrapper__output__iframe${!isVisibleOutFrame ? ' non-visible' : ''
							}`}
					>
						<OutputIframeContainer
							value={output}
							isDarkMode={isDarkMode}
						/>
					</div>
					{!isVisibleOutFrame && (
						<OutputTextContainer
							value={output}
							isDarkMode={isDarkMode}
						/>
					)}
				</div>
			</Panel>
		</SplitPane>
	);
}

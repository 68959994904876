import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';

import s from './Shared.scss';

export interface IModalCodeNamingProps {
	placeholder?: string;
	value?: string;
	onChange?: (value: string) => void;
	onBlur?: () => void;
	onFocus?: () => void;
	width?: string;
	height?: string;
	maxLength?: number;
}

export function TextInput({
	placeholder,
	value,
	onChange,
	onBlur,
	onFocus,
	height,
	width,
	maxLength,
}: IModalCodeNamingProps): JSX.Element {
	useStyles(s);

	return (
		<div className="sl-text-input">
			<input
				style={{
					height,
					width,
				}}
				className="sl-text-input__input"
				value={value}
				onChange={(event) => onChange(event.target.value)}
				placeholder={placeholder}
				onBlur={onBlur}
				onFocus={onFocus}
				maxLength={maxLength}
			/>
		</div>
	);
}

import React, { useEffect, memo, useState } from 'react';
import { merge, Subject } from 'rxjs';

import { takeUntil, tap } from 'rxjs/operators';
import { Container } from '../../../../symphony';

import { SlPlaygroundContext } from '../../playground-context';
import { SlPlaygroundCodeService } from '../../services/sl-playground-code.service';
import { IOutput } from '../../../../shared/public/SlCodeEditorComponents/types/types';
import { OutputText } from '../../../../shared/public/SlCodeEditorComponents/OutputText/OutputText';

export interface IOutputTextContainerProps {
	value: IOutput;
	isDarkMode: boolean;
}

export const OutputTextContainer: React.FC<IOutputTextContainerProps> = memo(
	({ value, isDarkMode }) => {
		const [isResizingPanel, setIsResizingPanel] = useState<boolean>(false);

		useEffect(() => {
			const unsubscriber = new Subject<void>();

			const { isResizingPanel$ } = Container.take(
				SlPlaygroundContext,
				SlPlaygroundCodeService,
			);
			merge(isResizingPanel$.pipe(tap(setIsResizingPanel)))
				.pipe(takeUntil(unsubscriber))
				.subscribe();

			return () => {
				unsubscriber.next();
				unsubscriber.complete();
			};
		}, []);

		return (
			<OutputText
				value={value}
				isDarkMode={isDarkMode}
				isResizingPanel={isResizingPanel}
			/>
		);
	},
);

export const useTopLevelDomain = (): string => {
	let href = typeof window !== 'undefined' ? window.location.origin : '';
	if (href.slice(-1) === '/') {
		href = href.slice(0, -1);
	}
	const indexOfPlayground = href.lastIndexOf('code');
	if (indexOfPlayground === -1) {
		return '';
	}
	const path = href.substring(
		indexOfPlayground,
		'code'.length + indexOfPlayground + 1,
	);

	return href.replace(path, '');
};

/* eslint-disable no-console */

export function windowOnErrorSetter(): void {
	window.onerror = (message, source, lineno, colno, error) => {
		console.error(error);
		return true;
	};
}

export function windowLoopProtectionHandler(): void {
	// @ts-ignore
	window.SL = {
		exitedLoop(loopID) {
			this.CodeTimer.exitedLoop(loopID);
		},
		shouldStopExecution(loopID) {
			const shouldStop = this.CodeTimer.shouldStopLoop(loopID);
			if (shouldStop === true) {
				console.warn(
					'Execution stopped as an infinite loop was detected.',
				);
			}
			return shouldStop;
		},
		CodeTimer: {
			// If we successfully run for X seconds no need to continue
			// to monitor because we know the program isn't locked
			programNoLongerBeingMonitored: false,
			timeOfFirstCallToShouldStopLoop: 0,
			loopExits: {},
			// Keep track of how long program spends in single loop w/o an exit
			loopTimers: {},

			// Give the program time to get started
			START_MONITORING_AFTER: 2000,
			// takes into account START_MONITORING_AFTER val
			STOP_ALL_MONITORING_TIMEOUT: 5000,
			// tested against code: xbwYNm, it loops over 200k real loop
			MAX_TIME_IN_LOOP_WO_EXIT: 2200,

			exitedLoop(loopID) {
				this.loopExits[loopID] = true;
			},

			shouldStopLoop(loopID) {
				// Once we kill a loop, kill them all, we have an infinite loop and
				// it must be fixed prior to running again.
				if (this.programKilledSoStopMonitoring) {
					return true;
				}

				// Program exceeded monitor time, we're in the clear
				if (this.programNoLongerBeingMonitored) {
					return false;
				}

				// If the loopExit already called return
				// It's possible for the program to break out
				if (this.loopExits[loopID]) {
					return false;
				}

				const now = this.getTime();

				if (this.timeOfFirstCallToShouldStopLoop === 0) {
					this.timeOfFirstCallToShouldStopLoop = now;
					// first call to shouldStopLoop so just exit already
					return false;
				}

				const programRunningTime = now - this.timeOfFirstCallToShouldStopLoop;

				// Allow program to run unmolested (yup that's the right word)
				// while it starts up
				if (programRunningTime < this.START_MONITORING_AFTER) {
					return false;
				}

				// Once the program's run for a satisfactory amount of time
				// we assume it won't lock up and we can simply continue w/o
				// checking for infinite loops
				if (programRunningTime > this.STOP_ALL_MONITORING_TIMEOUT) {
					this.programNoLongerBeingMonitored = true;

					return false;
				}

				// Second level shit around new hotness logic
				try {
					this.checkOnInfiniteLoop(loopID, now);
				} catch (e) {
					this.sendErrorMessageToEditor();
					this.programKilledSoStopMonitoring = true;
					return true;
				}

				return false;
			},

			sendErrorMessageToEditor() {
				try {
					if (this.shouldPostMessage()) {
						const data = {
							action: 'infinite-loop',
							line: this.findAroundLineNumber(),
						};

						parent.postMessage(JSON.stringify(data), '*');
					} else {
						this.throwAnErrorToStopCode();
					}
				} catch (error) {
					this.throwAnErrorToStopCode();
				}
			},

			shouldPostMessage() {
				return document.location.href.match(/boomerang/);
			},

			throwAnErrorToStopCode() {
				throw new Error(
					'Execution stopped as an infinite loop was detected.',
				);
			},

			findAroundLineNumber() {
				const err = new Error();
				let lineNumber = 0;

				if (err.stack) {
					// match only against JS in boomerang
					const m = err.stack.match(/boomerang\S+:(\d+):\d+/);

					if (m) {
						lineNumber = +m[1];
					}
				}

				return lineNumber;
			},

			// eslint-disable-next-line consistent-return
			checkOnInfiniteLoop(loopID, now): boolean | void {
				if (!this.loopTimers[loopID]) {
					this.loopTimers[loopID] = now;
					// We just started the timer for this loop. exit early
					return false;
				}

				const loopRunningTime = now - this.loopTimers[loopID];

				if (loopRunningTime > this.MAX_TIME_IN_LOOP_WO_EXIT) {
					throw new Error(
						'Execution stopped as an infinite loop was detected.',
					);
				}

				return true;
			},

			getTime() {
				return +new Date();
			},
		},
	};
}

import { Observable, of, tap } from 'rxjs';

import { Container, Service } from '../../../../symphony';

import { CommonService } from '../Business/common.service';
import { SlPlaygroundContext } from '../../playground-context';
import { IActionData } from '../../global-interface';
import { EventsService } from '../Business/events.service';
import { TrackingService } from '../Business/tracking.service';
import { SlPlaygroundApiService } from '../sl-playground-api.service';
import { SlPlaygroundCodeService } from '../sl-playground-code.service';
import { VotingService } from '../Business/voting.service';
import { getInitialServerSideData } from '../../../../shared/public/SlUtils/getInitialServerSideData';
import { IVoteUserCodeResponse } from '../../../../api/public/code-api';

@Service()
export class ActionsController {
	private commonService = Container.take(SlPlaygroundContext, CommonService);

	private eventsService = Container.take(SlPlaygroundContext, EventsService);

	private votingService = Container.take(SlPlaygroundContext, VotingService);

	private dataService = Container.take(
		SlPlaygroundContext,
		SlPlaygroundCodeService,
	);

	private apiService = Container.take(
		SlPlaygroundContext,
		SlPlaygroundApiService,
	);

	private trackingService = Container.take(
		SlPlaygroundContext,
		TrackingService,
	);

	// Component Events
	public onLanguageTypesMountHandler = (): Observable<null> => this.eventsService.languageTypesMountHandler();

	// Click Events
	public onLanguageItemClickHandler = ({
		programLanguage,
		clickType,
		section,
	}: IActionData): Observable<null> => this.eventsService.languageItemClickHandler(
		programLanguage,
		clickType,
		section,
	);

	public onPositiveReactionClickHandler = ({
		language,
	}: IActionData): Observable<null> => this.eventsService.onPositiveReactionClick({ language });

	// Callback events
	public authResponseHandler = ({
		authResponse,
	}: IActionData): Observable<null> => this.commonService.authResponseHandler(authResponse);

	// Track event
	public trackHandler = ({ trackData }: IActionData): Observable<null> => this.trackingService.track(trackData);

	// Network event
	public getLanguagesHandler = (): Observable<null> => {
		const ssrLanguages = getInitialServerSideData('ssrLanguages');

		if (ssrLanguages) {
			this.dataService.languages$.next(ssrLanguages);

			return of(null);
		}

		this.apiService
			.getLanguages()
			.pipe(
				tap((languagesRes) => {
					this.dataService.languages$.next(languagesRes);
				}),
			)
			.subscribe();

		return of(null);
	};

	public getLanguagesCatalogHandler = (): Observable<null> => {
		const ssrCompilerCatalog = getInitialServerSideData('ssrCompilerCatalog');

		if (ssrCompilerCatalog) {
			this.dataService.languageCatalog$.next(ssrCompilerCatalog);

			return of(null);
		}

		this.apiService
			.getLanguagesCatalog()
			.pipe(
				tap((languagesRes) => {
					this.dataService.languageCatalog$.next(languagesRes.data);
				}),
			)
			.subscribe();

		return of(null);
	};

	public updatePositiveReactionHandler = (): Observable<IVoteUserCodeResponse> => this.votingService.updatePositiveReaction();

	public triggerNotificationVisibilityHandler = () => this.eventsService.triggerNotificationVisibility();
}

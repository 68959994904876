import React, {
	useCallback, useEffect, useMemo, useState,
} from 'react';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { merge, Subject } from 'rxjs';

import useStyles from 'isomorphic-style-loader/useStyles';
import { Container } from '../../../../symphony';

import {
	IOutput,
	SlPlaygroundCodeService,
} from '../../services/sl-playground-code.service';
import { OutputIframeContainer } from '../../components/Output/OutputIframeContainer';
import { EditorWithLabel } from '../../components/EditorWithLabel/EditorWithLabel';
import { SlPlaygroundContext } from '../../playground-context';
import CodeLoader from '../../components/Loader/CodeLoader/CodeLoader';
import { OutputConsole } from '../../components/Output/OutputConsole/OutputConsole';

import s from './Web.scss';
import { getInitialStateForWeb } from '../../utils/helpers';
import { ICollapsedPanels, SplitPane } from '../../../../shared/public/SlCodeEditorComponents/SplitPane/SplitPane';
import { Orientation } from '../../../../shared/public/SlCodeEditorComponents/types/types';
import { Panel } from '../../../../shared/public/SlCodeEditorComponents/Panel/Panel';
import { OverlapLayer } from '../../../../shared/public/SlCodeEditorComponents/OverlapLayer/OverlapLayer';
import { CollapsedView } from '../../../../shared/public/SlCodeEditorComponents/CollapsedView/CollapsedView';
import { CodeLanguages } from '../../../../api/private/global.interface';
import { OutputHeader } from '../../../../shared/public/SlCodeEditorComponents/OutputHeader/OutputHeader';
import { useContainerData } from '../../../../shared/public/SlHooks/useContainerData';

interface IWebProps {
	language: CodeLanguages;
}

export function Web({ language }: IWebProps): JSX.Element {
	useStyles(s);
	const [output, setOutput] = useState<IOutput>(null);
	const [isResizingPanel, setIsResizingPanel] = useState<boolean>(false);
	const [isDarkMode, setIsDarkMode] = useState<boolean>(true);
	const [collapsedPanels, setCollapsedPanels] = useState<ICollapsedPanels>(
		{},
	);

	const { initialCollapsesPanels, initialPanelWith } = getInitialStateForWeb(language);

	const [collapsesStates, setCollapsesStates] = useState(
		initialCollapsesPanels,
	);
	const [showConsole, setShowConsole] = useState<boolean>(true);

	const {
		jsCode$,
		cssCode$,
		sourceCode$,
		showConfirmLeaveAlert$,
		isResizingPanel$,
		output$,
		isDarkMode$,
		showConsole$,
		outputConsole$,
	} = Container.take(SlPlaygroundContext, SlPlaygroundCodeService);

	const { outputConsole$: outputConsole, codeData$: codeData } = useContainerData(SlPlaygroundContext, SlPlaygroundCodeService, ['outputConsole$', 'codeData$']);

	useEffect(() => {
		const unsubscriber = new Subject<void>();

		merge(
			output$.pipe(tap(setOutput)),
			isDarkMode$.pipe(tap(setIsDarkMode)),
			showConsole$.pipe(tap(setShowConsole)),
			isResizingPanel$.pipe(tap(setIsResizingPanel)),
			outputConsole$.pipe(
				tap((data) => {
					if (data) {
						// console.log(data);
					}
				}),
			),
		)
			.pipe(takeUntil(unsubscriber))
			.subscribe();

		return () => {
			unsubscriber.next();
			unsubscriber.complete();
		};
	}, []);

	const onWebCodeChange = (value: string, type: string): void => {
		if (!showConfirmLeaveAlert$.value) {
			showConfirmLeaveAlert$.next(true);
		}

		switch (type) {
		case 'html':
			sourceCode$.next(value);
			break;
		case 'css':
			cssCode$.next(value);
			break;
		case 'js':
			jsCode$.next(value);
			break;
		default:
			break;
		}
	};

	const onMouseDown = (): void => {
		isResizingPanel$.next(true);
	};

	const onMouseUp = (): void => {
		isResizingPanel$.next(false);
	};

	const handleResize = useCallback(
		(sizes) => {
			let collapseStateChange = false;
			let mergeState = collapsesStates;
			const MIN_WIDTH = 48;

			sizes.forEach((size) => {
				if (size.width <= MIN_WIDTH) {
					if (!mergeState[size.id]) {
						collapseStateChange = true;
						mergeState = {
							...collapsesStates,
							[size.id]: true,
						};
					}
				} else if (size.width > MIN_WIDTH) {
					if (mergeState[size.id]) {
						collapseStateChange = true;
						mergeState = {
							...collapsesStates,
							[size.id]: false,
						};
					}
				}
			});
			if (collapseStateChange) {
				setCollapsesStates(mergeState);
			}
		},
		[collapsesStates],
	);

	const onCollapseClick = (id: string, orientation: Orientation) => (): void => {
		setCollapsedPanels({
			state: true,
			currentId: id,
			[id]: true,
			orientation,
		});
		setCollapsesStates({
			...collapsesStates,
			[id]: false,
		});
	};

	const callbackCollapseClicked = (id: string): void => {
		setCollapsedPanels({
			state: false,
			currentId: id,
			[id]: false,
			orientation: 'horizontal',
		});
	};

	const consoleStyleOptions = useMemo((): {
		[key: string]: string;
	} => ({
		height: showConsole ? '232px' : '0',
		display: showConsole ? 'flex' : 'none',
	}), [showConsole]);

	const getOutput = () => (
		<div className="sl-web-wrapper__frame-wrapper">
			<OutputHeader title="BROWSER" />
			<SplitPane
				callbackCollapseClicked={callbackCollapseClicked}
				defaultWidth={25}
				horizontal={false}
				onMouseUp={onMouseUp}
				onMouseDown={onMouseDown}
				onResize={handleResize}
				collapsedPanels={collapsedPanels}
				className="split-pane__vertical"
			>
				<Panel
					hideResizer
					style={showConsole ? {} : { height: '100%' }}
				>
					<div className="sl-web-wrapper__frame-wrapper__iframe">
						<OverlapLayer isResizingPanel={isResizingPanel} />
						<OutputIframeContainer
							value={output}
							isWeb
							isDarkMode={isDarkMode}
						/>
					</div>
				</Panel>
				<Panel isDarkMode={isDarkMode} style={{ height: outputConsole ? '200px' : '56px' }}>
					<OutputConsole isDarkMode={false} />
				</Panel>
			</SplitPane>
		</div>
	);

	if (!codeData) {
		return null;
	}

	const { jsCode, cssCode, sourceCode } = codeData;

	return (
		<SplitPane
			horizontal
			onResize={handleResize}
			className="sl-web-wrapper"
			onMouseUp={onMouseUp}
			onMouseDown={onMouseDown}
			collapsedPanels={collapsedPanels}
			callbackCollapseClicked={callbackCollapseClicked}
			defaultCollapsedStatesKeys={Object.keys(collapsesStates)}
			defaultWidth={25}
			isWeb
			components={{
				loader: <CodeLoader />,
			}}
		>
			<Panel isDarkMode={isDarkMode} width={initialPanelWith['0']}>
				<div className="panel-wrapper">
					<CollapsedView
						show={collapsesStates['0']}
						title="html"
						isDarkMode={isDarkMode}
						onClick={onCollapseClick('0', 'horizontal')}
					/>
					<EditorWithLabel
						value={sourceCode}
						language={CodeLanguages.HTML}
						onChange={onWebCodeChange}
						isDarkMode={isDarkMode}
					/>
				</div>
			</Panel>
			<Panel isDarkMode={isDarkMode} width={initialPanelWith['1']}>
				<div className="panel-wrapper">
					<CollapsedView
						show={collapsesStates['1']}
						isDarkMode={isDarkMode}
						title="css"
						onClick={onCollapseClick('1', 'horizontal')}
					/>
					<EditorWithLabel
						value={cssCode}
						language={CodeLanguages.CSS}
						onChange={onWebCodeChange}
						isDarkMode={isDarkMode}
					/>
				</div>
			</Panel>
			<Panel isDarkMode={isDarkMode} width={initialPanelWith['2']}>
				<div className="panel-wrapper">
					<CollapsedView
						show={collapsesStates['2']}
						isDarkMode={isDarkMode}
						title="js"
						onClick={onCollapseClick('2', 'horizontal')}
					/>
					<EditorWithLabel
						value={jsCode}
						language={CodeLanguages.JS}
						onChange={onWebCodeChange}
						isDarkMode={isDarkMode}
					/>
				</div>
			</Panel>
			<Panel isDarkMode={isDarkMode} width={initialPanelWith['3']}>{getOutput()}</Panel>
		</SplitPane>
	);
}

import { Observable, of, tap } from 'rxjs';

import { AuthService, Container, Service } from '../../../../symphony';
import { SlPlaygroundContext } from '../../playground-context';
import { SlPlaygroundApiService } from '../sl-playground-api.service';
import { SlPlaygroundCodeService } from '../sl-playground-code.service';
import { getBackendErrorMessage } from '../../utils/helpers';
import { IFlashMessageTypes } from '../../../../shared/public/SlFlashMessage/SlFlashMessage';
import { IVoteUserCodeResponse } from '../../../../api/public/code-api';

@Service()
export class VotingService {
	private api = Container.take(SlPlaygroundContext, SlPlaygroundApiService);

	private authService = Container.take('global', AuthService);

	private dataService = Container.take(
		SlPlaygroundContext,
		SlPlaygroundCodeService,
	);

	private updateCodeVoteNumber = (votes: number, vote: number) => {
		this.dataService.codeData$.next({
			...this.dataService.codeData$.value,
			votes,
			vote,
		});
	};

	public updatePositiveReaction = (): Observable<IVoteUserCodeResponse> => {
		const xp = this.authService.getUser()?.xp;
		if (xp < 50) {
			this.dataService.notificationData$.next({
				message: getBackendErrorMessage(257),
				type: IFlashMessageTypes.ERROR,
			});
			return of(null);
		}
		const id = this.dataService.codeData$.value?.id;
		const vote = this.dataService.codeData$.value.vote === 0 ? 1 : 0;

		return this.api.voteCode({ id, vote }).pipe(
			tap((res) => {
				if (res.error) {
					this.dataService.notificationData$.next({
						message: getBackendErrorMessage(res.error.data),
						type: IFlashMessageTypes.ERROR,
					});

					return;
				}
				this.updateCodeVoteNumber(res.votes, vote);
			}),
		);
	};
}

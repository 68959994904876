import React from 'react';

import { Button } from '@sololearnorg/sol';

export interface IHeaderSaveBtnProps {
	onClick: () => void;
	loading?: boolean;
	title: string | JSX.Element;
	disabled?: boolean;
	isDarkMode?: boolean;
}

export const HeaderSaveBtn: React.FC<IHeaderSaveBtnProps> = React.memo(
	({
		onClick, loading, title = 'Save', disabled, isDarkMode,
	}) => (
		<Button
			onClick={onClick}
			buttonType="secondary"
			size="s"
			className={
				isDarkMode ? 'sl-action-button--secondary--dark' : ''
			}
			disabled={disabled}
			loading={loading}
		>
			{title}
		</Button>
	),
);

import React, { memo, useState } from 'react';

import { Button } from '@sololearnorg/sol';
import useStyles from 'isomorphic-style-loader/useStyles';
import { i18n } from '../../../../symphony';
import { TextInput } from '../shared/TextInput';
import { getInitialDate } from '../../utils/helpers';

import s from './Modals.scss';

export interface IModalCodeNamingProps {
	placeholder?: string;
	value?: string;
	showDiscardButton?: boolean;
	onDiscardButtonPress?: () => void;
	onSaveBtnPress?: (text: string) => void;
	title?: string;
	height?: string;
	width?: string;
}

export const ModalCodeNaming: React.FC<IModalCodeNamingProps> = memo(
	({
		value,
		showDiscardButton,
		onDiscardButtonPress,
		onSaveBtnPress,
		placeholder,
		title,
		height = '204px',
		width = '478px',
	}) => {
		useStyles(s);
		const [inputValue, setInputValue] = useState<string>(
			value || getInitialDate(),
		);

		const onInputChange = (input: string): void => {
			setInputValue(input);
		};

		const handleSaveBtnPress = () => {
			onSaveBtnPress(inputValue);
		};

		return (
			<div
				className="sl-modal-code-naming"
				style={{
					height,
					width,
				}}
			>
				<p className="sl-modal-code-naming__title">{title}</p>
				<div className="sl-modal-code-naming__margins">
					<TextInput
						placeholder={placeholder}
						value={inputValue}
						onChange={onInputChange}
						width="360px"
						maxLength={128}
					/>
				</div>
				<div className="sl-modal-code-naming__margins sl-modal-code-naming__flex-row">
					{showDiscardButton && (
						<div
							onClick={onDiscardButtonPress}
							className="sl-modal-code-naming__discard"
						>
							{i18n.t(
								'web-playground.save-code-modal-cancel-button',
							)}
						</div>
					)}
					<Button
						onClick={handleSaveBtnPress}
						size="s"
						disabled={!inputValue.trim()}
						style={{
							width: '125px',
						}}
					>
						{i18n.t('web-playground.save-code-modal-submit-button')}
					</Button>
				</div>
			</div>
		);
	},
);

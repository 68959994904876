import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import {
	AuthService,
	Container,
	history,
	ModalService,
	Service,
	track,
} from '../../../symphony';
import { FLASH_MESSAGES } from '../constants/FlashMessages';
import { SlPlaygroundContext } from '../playground-context';
import { ActionTypes } from '../constants/ActionTypes';
import {
	checkForInputs,
	getStructuredWebCode,
	wrapByTag,
} from '../utils/helpers';
import { getIsDark, setIsDark } from '../utils/storageManager';
import {
	IError,
	IModalStates,
	IModalTypes,
	NavigationClickType,
	TouchPoint,
	TrackEventName,
	UserMenuItemName,
} from '../global-interface';
import { SlPlaygroundApiService } from './sl-playground-api.service';
import { ErrorCodes, handleError } from '../utils/networkErrorHandling';
import { SlPlaygroundTrackingService } from './sl-playground-tracking.service';
import {
	IUpdatedCodeTemplate,
	SlPlaygroundCodeService,
} from './sl-playground-code.service';
import { ILanguageType, LANGUAGE_TYPES } from '../constants/LanguageTypes';
import { getInitialServerSideData } from '../../../shared/public/SlUtils/getInitialServerSideData';
import { IFlashMessageTypes } from '../../../shared/public/SlFlashMessage/SlFlashMessage';
import { LanguageRoutes, MappingRouteLanguageToDefaultCodeGetting } from '../../../shared/public/globalInterfaces/globalInterfaces';
import { checkIsWeb } from '../../../shared/public/SlCodeEditorComponents/utils/helperFunctions';
import {
	DefaultCodeLanguages, ICodeCompilationResponse, ICodeDefaultResponse, ICodeTemplateResponse, IOutputType, IUserCode, IUserCodePayload, IUserCodeResponse,
} from '../../../api/public/code-api';
import { CodeLanguages } from '../../../api/private/global.interface';

@Service()
export class SlPlaygroundActionHandlersService {
	private api = Container.take(SlPlaygroundContext, SlPlaygroundApiService);

	private codeService = Container.take(
		SlPlaygroundContext,
		SlPlaygroundCodeService,
	);

	private modalService = Container.take('global', ModalService);

	private isTryItYourself: boolean = Container.take(
		SlPlaygroundContext,
		'isTryItYourself',
	);

	private authService = Container.take('global', AuthService);

	public getUserCodeHandler = (id: string): Observable<IUserCodeResponse> => {
		const ssrUserCode = getInitialServerSideData('ssrUserCode');
		if (ssrUserCode) {
			this.setCodeData(ssrUserCode.data);
			return of(null);
		}
		this.codeService.loading$.next(true);
		return this.api.getUserCode(id).pipe(
			tap((response) => {
				this.codeService.loading$.next(false);
				this.setCodeData(response.data);
			}),
			catchError((err: IError[]) => {
				handleError(err);
				return of(null);
			}),
		);
	};

	private handleDefaultCodeResponse = (res) => {
		const formattedData = {
			...res?.data,
			sourceCode: res?.data.code,
		};
		delete formattedData.id;
		this.setCodeData(formattedData);
	};

	public getDefaultCodeHandler = (
		defaultCodeLanguage: DefaultCodeLanguages,
	): Observable<ICodeDefaultResponse> => {
		const ssrDefaultCode = getInitialServerSideData('ssrDefaultCode');
		if (ssrDefaultCode) {
			this.handleDefaultCodeResponse(ssrDefaultCode);
			return of(null);
		}

		this.codeService.loading$.next(true);
		return this.api.getDefaultCode(defaultCodeLanguage).pipe(
			catchError((err: IError[]) => {
				handleError(err);
				return of(null);
			}),
			tap((response) => {
				this.handleDefaultCodeResponse(response);
				this.codeService.loading$.next(false);
			}),
		);
	};

	public getTemplateHandler = (
		templateId: number,
		errorHandler: { [key: string]: () => void; },
		courseId?: number,
	): Observable<ICodeTemplateResponse> => {
		this.codeService.loading$.next(true);
		return this.api.getTemplate(templateId, courseId).pipe(
			catchError((err) => {
				this.codeService.loading$.next(false);
				handleError(err, {
					...errorHandler,
				});
				return of(null);
			}),
			tap((response) => {
				const formattedData = {
					...response?.data,
					sourceCode: response?.data.code,
				};
				this.codeService.loading$.next(false);
				this.setCodeData(formattedData);
			}),
		);
	};

	public saveUserCodeHandler = (
		data: IUserCodePayload,
		isPublicChange?: boolean,
	): Observable<IUserCodeResponse> => {
		const code = this.mutateDataBeforeSaving(data);
		const { animationLoader$ } = this.codeService;
		animationLoader$.next(true);

		return this.api.saveUserCode(code).pipe(
			tap((response: IUserCodeResponse) => {
				this.tagManager$.next({
					event: 'SaveCode',
				});
				this.setCodeData(response?.data, isPublicChange);
				animationLoader$.next(false);
				const onClose: () => void = Container.take(
					SlPlaygroundContext,
					'onClose',
				);
				this.continueAfterCodeSavingHandler({
					callback: () => onClose(),
					publicId: response?.data.publicId,
					isTryItYourself: this.isTryItYourself,
				});
			}),
			catchError((err: IError[]) => {
				handleError(err, {
					[ErrorCodes.error400]: () => {
						this.codeService.flashMessage$.next({
							type: IFlashMessageTypes.ERROR,
							message: FLASH_MESSAGES.couldNotSaveCode,
						});
					},
				});
				animationLoader$.next(false);

				return of(null);
			}),
		);
	};

	public publishCodeHandler = (
		data: IUserCodePayload,
	): Observable<IUserCodeResponse> => {
		this.codeService.loading$.next(true);
		const localData = { ...data };
		if (
			localData.language === CodeLanguages.HTML
			|| localData.language === CodeLanguages.CSS
			|| localData.language === CodeLanguages.JS
		) {
			localData.language = CodeLanguages.WEB;
		}
		return this.api.publishCode(localData).pipe(
			catchError((err: IError[]) => {
				handleError(err, {
					[ErrorCodes.error400]: () => {
						this.codeService.flashMessage$.next({
							type: IFlashMessageTypes.ERROR,
							message: FLASH_MESSAGES.couldNotSaveCode,
						});
					},
				});
				this.setCodeData(this.codeService.codeData$.value);
				this.codeService.loading$.next(false);

				return of(null);
			}),
			tap((response: IUserCodeResponse) => {
				this.codeService.loading$.next(false);
				this.setCodeData(response.data);
			}),
		);
	};

	public compileCodeHandler = (
		data: ICompileCodeHandlerArgs,
	): Observable<ICodeCompilationResponse> => {
		const {
			code, language, input, codeId,
		} = data;
		this.codeService.animationLoader$.next(true);
		return this.api
			.compileCode({
				code,
				language,
				input,
				codeId,
			})
			.pipe(
				catchError((err: IError[]) => {
					handleError(err, {
						[ErrorCodes.error400]: () => {
							this.codeService.flashMessage$.next({
								type: IFlashMessageTypes.ERROR,
								message: FLASH_MESSAGES.couldNotCompileCode,
							});
						},
					});
					this.codeService.animationLoader$.next(false);
					return of(null);
				}),
				tap((response: ICodeCompilationResponse) => {
					this.tagManager$.next({
						event: 'RunnedCode',
						language,
					});
					this.codeService.output$.next(
						this.mutateOutputResponse(response.data),
					);
					this.codeService.animationLoader$.next(false);
				}),
			);
	};

	private mutateOutputResponse = (data, isDark?: boolean) => {
		const { isDarkMode$ } = this.codeService;
		let css = '';
		let htmlCode = data?.output;
		const darkMode = isDark || isDarkMode$.value;
		if (data?.outputType === IOutputType.html && data?.outputStyle) {
			const localDocument = new DOMParser().parseFromString(
				htmlCode,
				'text/html',
			);
			if (localDocument.getElementById('custom-style')) {
				localDocument.getElementById('custom-style').remove();
				htmlCode = localDocument.documentElement.innerHTML;
				htmlCode = wrapByTag(htmlCode, 'html');
			}
			const style = darkMode
				? data?.outputStyle.dark
				: data?.outputStyle.light;
			css = `<style id="custom-style">${style} </style>`;
			const includeHead = htmlCode ? htmlCode.includes('<head>') : false;
			const htmlTagIndex = htmlCode.indexOf('<html>') + 6;
			if (!includeHead) {
				htmlCode = [
					htmlCode.slice(0, htmlTagIndex),
					`<head>${css}</head>`,
					htmlCode.slice(htmlTagIndex),
				].join('');
			} else {
				const closeHeadTagIndex = htmlCode.indexOf('</head>');
				htmlCode = [
					htmlCode.slice(0, closeHeadTagIndex),
					css,
					htmlCode.slice(closeHeadTagIndex),
				].join('');
			}
		}
		return {
			...data,
			output: htmlCode,
		};
	};

	public compileWebCodeHandler = (
		sourceCode: string,
		cssCode: string,
		jsCode: string,
	): void => {
		const result = getStructuredWebCode(sourceCode, cssCode, jsCode);
		this.codeService.output$.next({
			output: result,
			outputType: IOutputType.html,
		});
	};

	public continueAfterCodeSavingHandler = (
		data: IContinueAfterCodeSavingHandlerArgs,
	): void => {
		const modalData = this.codeService.modalData$.value;

		if (modalData?.type === IModalTypes.CLOSE) {
			this.codeService.codeSavingActionType$.next(ActionTypes.INITIAL);
			if (data.callback) {
				data.callback();
			}
			this.codeService.loading$.next(false);
			return;
		}
		if (data?.publicId && !data?.isTryItYourself) {
			history.replace(`/compiler-playground/${data.publicId}`);
		}
		if (
			modalData?.type === IModalTypes.SAVE
			|| modalData?.type === IModalTypes.RENAME
		) {
			setTimeout(() => {
				this.codeService.codeSavingActionType$.next(
					ActionTypes.INITIAL,
				);
			}, 1000);
		}

		if (modalData?.type === IModalTypes.PUBLISH) {
			this.codeService.codeSavingActionType$.next(ActionTypes.INITIAL);
		}
	};

	public changeEditorModeHandler = (state: boolean): void => {
		const { output$, isDarkMode$ } = this.codeService;
		isDarkMode$.next(state);
		setIsDark(state);
		output$.next(this.mutateOutputResponse(output$.value, state));
	};

	private mutateDataBeforeSaving = (code) => {
		const mutatedData = { ...code };
		if (
			mutatedData.language === CodeLanguages.HTML
			|| mutatedData.language === CodeLanguages.CSS
			|| mutatedData.language === CodeLanguages.JS
		) {
			mutatedData.language = CodeLanguages.WEB;
		}
		return mutatedData;
	};

	public getCodeDataHandler = (
		data: IGetCodeDataHandlerArgs,
	): Observable<ICodeTemplateResponse | IUserCodeResponse> => {
		const { language } = data;

		const templateId: string = Container.take(
			SlPlaygroundContext,
			'templateId',
		);
		const courseId: string = Container.take(
			SlPlaygroundContext,
			'courseId',
		);
		if (+templateId) {
			const errorHandler: { [key: string]: () => void; } = Container.take(
				SlPlaygroundContext,
				'errorHandler',
			);
			return this.getTemplateHandler(
				+templateId,
				errorHandler,
				+courseId,
			);
		}
		const languageType = LANGUAGE_TYPES.find(
			(item) => item?.path === language,
		);
		if (languageType) {
			const defaultCodeLanguage = MappingRouteLanguageToDefaultCodeGetting[language]?.defaultCodeApi;
			return this.getDefaultCodeHandler(defaultCodeLanguage);
		}
		return this.getUserCodeHandler(language as string);
	};

	private trackAction$ = Container.take(
		SlPlaygroundContext,
		SlPlaygroundTrackingService,
	).trackAction$;

	private tagManager$ = Container.take(
		SlPlaygroundContext,
		SlPlaygroundTrackingService,
	).tagManager$;

	public setCodeData = (
		code: IUserCode | IUpdatedCodeTemplate,
		isPublicChange?: boolean,
	): Observable<null> => {
		let localCodeData = { ...code };
		const {
			isPublic$,
			isWeb$,
			sourceCode$,
			cssCode$,
			jsCode$,
			codeName$,
			codeData$,
			showConfirmLeaveAlert$,
		} = this.codeService;

		showConfirmLeaveAlert$.next(false);
		if (
			code?.userId
			&& this.authService.getUser()?.id !== localCodeData?.userId
		) {
			this.trackAction$.next({
				action: 'view',
				element: 'codeplayground_other_usercodes',
				entityId: localCodeData?.id,
				userId: this.authService.getUser()?.id.toString(),
			});
		}
		if (!code.language || code.language === CodeLanguages.RAW) {
			const language: CodeLanguages = Container.take(
				SlPlaygroundContext,
				'language',
			);
			localCodeData = {
				...code,
				language,
			};
		}
		const localIsWeb = checkIsWeb(localCodeData.language);
		if (localIsWeb) {
			isWeb$.next(localIsWeb);
		}
		if (localCodeData.name) {
			codeName$.next(localCodeData.name);
		}
		isPublic$.next(localCodeData.isPublic || false);

		if (!isPublicChange) {
			sourceCode$.next(localCodeData.sourceCode);
			jsCode$.next(localCodeData.jsCode);
			cssCode$.next(localCodeData.cssCode);
			codeData$.next(localCodeData);
		}

		return of(null);
	};

	public initAppHandler = (topLevelDomain: string): void => {
		this.codeService.topLevelDomain$.next(topLevelDomain);
		this.tagManager$.next({
			event: 'ViewPlayground',
		});
	};

	public initPlaygroundHandler = (): void => {
		const isDark = getIsDark();
		this.codeService.isDarkMode$.next(isDark);
	};

	public resetContainerHandler = (): Observable<null> => {
		const {
			cssCode$,
			jsCode$,
			sourceCode$,
			output$,
			isWeb$,
			isPublic$,
			modalData$,
			codeData$,
			codeName$,
			showConfirmLeaveAlert$,
		} = this.codeService;

		isWeb$.next(false);
		output$.next(null);
		codeData$.next(null);
		cssCode$.next('');
		jsCode$.next('');
		sourceCode$.next('');
		codeName$.next('');
		isPublic$.next(false);
		modalData$.next(null);
		showConfirmLeaveAlert$.next(false);

		return of(null);
	};

	private onCompileCode = (): Observable<ICodeCompilationResponse> => {
		const { codeData$, sourceCode$, inputs$ } = this.codeService;
		const templateId: string = Container.take(
			SlPlaygroundContext,
			'templateId',
		);
		const quizId: number = Container.take(SlPlaygroundContext, 'quizId');

		const languageType = LANGUAGE_TYPES.find((item) => item.language === codeData$.value.language);

		this.trackAction$.next({
			element: this.isTryItYourself
				? 'TIY_run_lesson'
				: `codeplayground_usercode_${languageType.path}`,
			action: this.isTryItYourself ? 'click' : 'run',
			userId: this.authService.getUser()?.id.toString(),
			entityId: this.isTryItYourself ? quizId : codeData$.value.id,
		});
		return this.compileCodeHandler({
			language: codeData$.value.language,
			code: sourceCode$.value,
			input: inputs$.value,
			codeId: templateId,
		});
	};

	private getElement = (languageType: ILanguageType): string => {
		const { codeData$ } = this.codeService;
		if (this.isTryItYourself) {
			return 'TIY_run_lesson';
		}

		return `codeplayground_usercode_${languageType ? languageType.path : codeData$.value.language}`;
	};

	private onCompileWebCode = ({ language }) => {
		const languageType = LANGUAGE_TYPES.find(
			(item) => item.path === language,
		);
		const {
			codeData$, sourceCode$, jsCode$, cssCode$,
		} = this.codeService;
		const quizId: number = Container.take(SlPlaygroundContext, 'quizId');

		this.trackAction$.next({
			element: this.getElement(languageType),
			action: this.isTryItYourself ? 'click' : 'run',
			userId: this.authService.getUser()?.id.toString(),
			entityId: this.isTryItYourself ? quizId : codeData$.value.id,
		});

		this.tagManager$.next({
			event: 'RunnedCode',
			language,
		});

		this.compileWebCodeHandler(
			sourceCode$.value,
			cssCode$.value,
			jsCode$.value,
		);
	};

	public onHeaderCompileCodeHandler = (): Observable<ICodeCompilationResponse> => {
		const {
			isWeb$, codeData$, sourceCode$, modalData$,
		} = this.codeService;
		const { tagManager$ } = Container.take(
			SlPlaygroundContext,
			SlPlaygroundTrackingService,
		);
		tagManager$.next({
			event: 'RunCode',
		});

		this.trackHandler(TrackEventName.OnRunCode, {
			language: codeData$.value.language,
		});

		if (!isWeb$.value) {
			if (checkForInputs(codeData$?.value.language, sourceCode$.value)) {
				modalData$.next({
					type: IModalTypes.RUN,
					state: IModalStates.OPEN,
				});
			} else {
				return this.onCompileCode();
			}
		} else {
			this.onCompileWebCode({
				language: codeData$.value.language,
			});
			return of(null);
		}
		return of(null);
	};

	private onSaveCode = (
		isPublicChange = false,
	): Observable<IUserCodeResponse> => {
		const { codeData$ } = this.codeService;
		this.trackAction$.next({
			element: this.isTryItYourself
				? 'codeplayground_TIY'
				: 'codeplayground_usercode',
			action: 'save',
			entityId: codeData$.value.id,
			userId: this.authService.getUser()?.id.toString(),
		});

		return this.saveUserCodeHandler(
			this.generateUserCode(isPublicChange),
			isPublicChange,
		);
	};

	private generateUserCode = (isPublicChange: boolean): IUserCodePayload => {
		const {
			codeData$,
			codeName$,
			isPublic$,
			jsCode$,
			cssCode$,
			sourceCode$,
		} = this.codeService;
		if (isPublicChange && codeData$.value) {
			const {
				sourceCode, jsCode, cssCode, id, language,
			} = codeData$.value;
			return {
				sourceCode,
				jsCode,
				cssCode,
				name: codeName$.value,
				isPublic: isPublic$.value,
				language,
				id,
			};
		}
		return {
			sourceCode: sourceCode$.value,
			jsCode: jsCode$.value,
			cssCode: cssCode$.value,
			name: codeName$.value,
			isPublic: isPublic$.value,
			language: codeData$.value?.language,
			id: codeData$.value?.id,
		};
	};

	public onHeaderSaveCodeHandler = (
		language: CodeLanguages,
	): Observable<IUserCodeResponse> => {
		const { codeData$, modalData$ } = this.codeService;
		if (!this.authService.isLoggedIn()) {
			this.openLoginModalHandler({
				touchPoint: TouchPoint.Save,
				language,
			});
		}
		if (
			this.authService.getUser()?.id !== codeData$.value.userId
			|| !codeData$.value.name
		) {
			modalData$.next({
				type: IModalTypes.SAVE,
				state: !this.authService.isLoggedIn()
					? IModalStates.CLOSE
					: IModalStates.OPEN,
			});
			return of(null);
		}
		return this.onSaveCode();
	};

	public onHeaderPublishCodeHandler = (
		state: boolean,
		language: CodeLanguages,
	): Observable<IUserCodeResponse> => {
		const { isPublic$, codeData$, modalData$ } = this.codeService;
		isPublic$.next(state);
		if (!this.authService.isLoggedIn()) {
			this.openLoginModalHandler({
				touchPoint: TouchPoint.Publish,
				language,
			});
		}
		if (
			this.authService.getUser()?.id !== codeData$.value.userId
			|| !codeData$.value.name
		) {
			modalData$.next({
				type: IModalTypes.PUBLISH,
				state: !this.authService.isLoggedIn()
					? IModalStates.CLOSE
					: IModalStates.OPEN,
			});
			return of(null);
		}
		return this.onSaveCode(true);
	};

	private checkCodeChanges = (): boolean => {
		const {
			codeData$, sourceCode$, cssCode$, jsCode$,
		} = this.codeService;
		return (
			codeData$.value.sourceCode !== sourceCode$.value
			|| codeData$.value.cssCode !== cssCode$.value
			|| codeData$.value.jsCode !== jsCode$.value
		);
	};

	private closePlaygroundHandler = () => {
		const { codeData$, modalData$ } = this.codeService;

		if (
			codeData$.value
			&& codeData$.value.language !== CodeLanguages.SQL
			&& this.checkCodeChanges()
		) {
			modalData$.next({
				type: IModalTypes.CLOSE,
				state: IModalStates.OPEN,
			});
		} else {
			const onClose: () => void = Container.take(
				SlPlaygroundContext,
				'onClose',
			);
			onClose();
		}
	};

	public onHeaderCloseIconHandler = (): void => {
		Container.register(
			SlPlaygroundContext,
			'afterCloseRedirectRoute',
			'/codes',
		);
		this.closePlaygroundHandler();
	};

	public onLogoClickHandler = (): void => {
		this.trackHandler(TrackEventName.NavigationClick, {
			clickType: NavigationClickType.Logo,
		});
		Container.register(SlPlaygroundContext, 'afterCloseRedirectRoute', '/');
		this.closePlaygroundHandler();
	};

	public onCourseClickHandler = (courseRoute): void => {
		Container.register(
			SlPlaygroundContext,
			'afterCloseRedirectRoute',
			courseRoute,
		);
		this.closePlaygroundHandler();
	};

	public onLanguageChangeHandler = (languageRoute): void => {
		Container.register(
			SlPlaygroundContext,
			'afterCloseRedirectRoute',
			languageRoute,
		);
		this.closePlaygroundHandler();
	};

	public onHeaderRenameCodeHandler = (
		language: CodeLanguages,
	): Observable<null> => {
		const { modalData$ } = this.codeService;
		if (!this.authService.isLoggedIn()) {
			this.openLoginModalHandler({
				touchPoint: TouchPoint.ChangeName,
				language,
			});
		}
		modalData$.next({
			type: IModalTypes.RENAME,
			state: !this.authService.isLoggedIn()
				? IModalStates.CLOSE
				: IModalStates.OPEN,
		});
		return of(null);
	};

	public onModalCompileCodeHandler = (): Observable<null> => of(null);

	public makeFreezeHandler = (): void => {
		this.codeService.freezeLayer$.next(true);
	};

	public toggleConsole = (): Observable<null> => {
		this.codeService.showConsole$.next(
			!this.codeService.showConsole$.value,
		);
		return of(null);
	};

	public openConsole = (): Observable<null> => {
		this.codeService.showConsole$.next(true);
		return of(null);
	};

	public SlTiyRunCall = (): Observable<null> => of(null);

	private buildRedirectRouteForUserMenu = (
		item: UserMenuItemName,
	): string => {
		const authService = Container.take('global', AuthService);

		let redirectRoute = '';

		if (item === UserMenuItemName.Profile) {
			this.trackUserMenuItemClick('gotoprofile');
			redirectRoute = '/profile';
		}

		if (item === UserMenuItemName.Settings) {
			this.trackUserMenuItemClick('settings');
			const user = authService.getUser();
			if (user?.id) {
				redirectRoute = `/profile/${user.id.toString()}?settings=true`;
			}
		}

		if (item === UserMenuItemName.Help) {
			this.trackUserMenuItemClick('help');
			redirectRoute = '/contact';
		}

		if (item === UserMenuItemName.Logout) {
			Container.register(SlPlaygroundContext, 'logoutHandler', () => {
				authService.logout({
					pathname: '/',
					type: 'internal',
				});
			});

			this.trackUserMenuItemClick('logout');
		}
		return redirectRoute;
	};

	public onUserMenuItemClickHandler = ({
		userMenuItemName,
	}: {
		userMenuItemName: UserMenuItemName;
	}): Observable<null> => {
		const { isUserMenuOpen$ } = Container.take(
			SlPlaygroundContext,
			SlPlaygroundCodeService,
		);

		isUserMenuOpen$.next(false);
		Container.register(
			SlPlaygroundContext,
			'afterCloseRedirectRoute',
			this.buildRedirectRouteForUserMenu(userMenuItemName),
		);
		this.closePlaygroundHandler();

		return of(null);
	};

	private trackUserMenuItemClick = (itemName: string) => {
		const { trackAction$ } = Container.take(
			SlPlaygroundContext,
			SlPlaygroundTrackingService,
		);
		trackAction$.next({
			action: 'click',
			element: `navigation_profile_${itemName}`,
			entityId: null,
		});
	};

	public onAvatarClickHandler = (): Observable<null> => {
		const { isUserMenuOpen$ } = Container.take(
			SlPlaygroundContext,
			SlPlaygroundCodeService,
		);

		isUserMenuOpen$.next(!isUserMenuOpen$.value);
		return of(null);
	};

	public onUserPhotoClickHandler = (): Observable<null> => {
		const { trackAction$ } = Container.take(
			SlPlaygroundContext,
			SlPlaygroundTrackingService,
		);
		trackAction$.next({
			action: 'click',
			element: 'navigation_profile',
			entityId: null,
		});
		return of(null);
	};

	public closeUserMenuHandler = (): Observable<null> => {
		const { isUserMenuOpen$ } = Container.take(
			SlPlaygroundContext,
			SlPlaygroundCodeService,
		);

		isUserMenuOpen$.next(false);
		return of(null);
	};

	public onStartCourseClickHandler = (
		language: CodeLanguages,
	): Observable<null> => {
		this.trackHandler(TrackEventName.NavigationClick, {
			clickType: NavigationClickType.StartCourse,
			language,
		});
		return of(null);
	};

	public onRegisterClickHandler = (
		language: CodeLanguages,
	): Observable<null> => {
		this.trackHandler(TrackEventName.NavigationClick, {
			clickType: NavigationClickType.Register,
			language,
		});
		return of(null);
	};

	public onLanguageSelectClickHandler = (
		language: CodeLanguages,
	): Observable<null> => {
		this.trackHandler(TrackEventName.NavigationClick, {
			clickType: NavigationClickType.Language,
			language,
		});
		return of(null);
	};

	public initNavigationHandler = (
		language: CodeLanguages,
	): Observable<null> => {
		this.trackHandler(TrackEventName.InitNavigation, {
			language,
		});
		return of(null);
	};

	public openLoginModalHandler = ({
		touchPoint,
		language,
	}: {
		touchPoint: TouchPoint;
		language: CodeLanguages;
	}): Observable<null> => {
		this.modalService.open('playgroundLoginModal', true);
		Container.register(SlPlaygroundContext, 'touchPoint', touchPoint);

		this.trackHandler(TrackEventName.InitSignUp, {
			touchPoint,
			language,
		});
		return of(null);
	};

	private trackHandler = (
		eventName: TrackEventName,
		options: {
			touchPoint?: TouchPoint;
			language?: CodeLanguages;
			clickType?: NavigationClickType;
		} = {},
	) => {
		const data: {
			touchpoint?: TouchPoint;
			program_language?: LanguageRoutes;
			click_type?: NavigationClickType;
		} = {};

		if (eventName === TrackEventName.InitSignUp) {
			data.touchpoint = options.touchPoint;
		}

		if (eventName === TrackEventName.NavigationClick) {
			data.click_type = options.clickType;
		}

		const language = this.codeService.codeData$.value?.language;
		data.program_language = LANGUAGE_TYPES.find((item) => {
			if (item.language === options.language) {
				return true;
			}
			if (
				language === CodeLanguages.HTML
				|| language === CodeLanguages.CSS
				|| language === CodeLanguages.JS
			) {
				return item.language === CodeLanguages.WEB;
			}

			return item.language === language;
		})?.path;

		// Todo send web instead of javascript (bug) /compiler/javascript
		// Remove this code after make a change the schemas about javascript programming language
		if (
			// @ts-ignore
			(options.language === 'javascript'
				&& data.program_language === LanguageRoutes.WEB)
			|| data.program_language === LanguageRoutes.JAVASCRIPT
		) {
			// @ts-ignore
			data.program_language = 'js';
		}

		track({
			...data,
			event_name: eventName,
			code_id: this.codeService.codeData$.value?.publicId || '',
		});
	};
}

export interface IGetCodeDataHandlerArgs {
	language: CodeLanguages | LanguageRoutes | string;
}

export interface ICompileCodeHandlerArgs {
	code: string;
	language: CodeLanguages;
	input?: string;
	codeId?: string;
}

export interface IContinueAfterCodeSavingHandlerArgs {
	callback: () => void;
	publicId: string;
	isTryItYourself: boolean;
}

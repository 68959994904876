import { Observable, of } from 'rxjs';
import {
	Container,
	Service,
	TrackingService as GlobalTrackingService,
} from '../../../../symphony';
import { ISlLoginResponse } from '../../../../login/private/global.interface';

declare const slTrack: (payload: {
	action: string;
	element: string;
	entityId?: number;
	redirectUrl?: string;
	userId?: string;
}) => void;

interface ITrackAction {
	action: string;
	element: string;
	entityId?: number;
	redirectUrl?: string;
	userId?: string;
}

@Service()
export class TrackingService {
	private globalTrackingService = Container.take(
		'global',
		GlobalTrackingService,
	);

	private static slTrackHandler(action: ITrackAction) {
		if (typeof window !== 'undefined') {
			slTrack({
				action: action.action,
				element: action.element,
				entityId: action.entityId || null,
				redirectUrl: action.redirectUrl || null,
				userId: action.userId || null,
			});
		}
	}

	public track = (trackingData): Observable<null> => {
		const { eventName, eventParams } = trackingData;

		this.globalTrackingService.tracking$.next({
			eventName,
			eventParams,
		});

		return of(null);
	};

	public authSuccess(authResponse: ISlLoginResponse): Observable<null> {
		if (authResponse.clickedButton) {
			const isNewRegistered = authResponse?.res.user.isNewRegisteredUser;
			let authTrackMapping: { [key: string]: string } = {
				login: 'playground_newsignin_email',
				signup: 'playground_signup_signup',
			};
			if (isNewRegistered) {
				authTrackMapping = {
					...authTrackMapping,
					googleLogin: 'playground_signup_google',
					googleSignup: 'playground_signup_google',
					facebookLogin: 'playground_signup_facebook',
					facebookSignup: 'playground_signup_facebook',
					appleLogin: 'playground_signup_apple',
					appleSignup: 'playground_signup_apple',
				};
			} else {
				authTrackMapping = {
					...authTrackMapping,
					googleLogin: 'playground_newsignin_google',
					googleSignup: 'playground_newsignin_google',
					facebookLogin: 'playground_newsignin_facebook',
					facebookSignup: 'playground_newsignin_facebook',
					appleLogin: 'playground_newsignin_apple',
					appleSignup: 'playground_newsignin_apple',
				};
			}

			TrackingService.slTrackHandler({
				action: 'login',
				element: authTrackMapping[authResponse.clickedButton],
			});
		}

		return of(null);
	}
}

import React, { memo, FC } from 'react';

import { SlPlaygroundCodeService } from '../../services/sl-playground-code.service';
import { SlPlaygroundContext } from '../../playground-context';
import { useContainerData } from '../../../../shared/public/SlHooks/useContainerData';
import { SlLoader } from '../../../../shared/public/SlLoader/SlLoader';

export interface ILoaderProps {
	backgroundColor?: string;
}
export const Loader: FC<ILoaderProps> = memo(({ backgroundColor = 'rgba(0,0,0,0.3)' }) => {
	const { loading$: loadingState } = useContainerData(
		SlPlaygroundContext,
		SlPlaygroundCodeService,
		['loading$'],
	);

	return loadingState ? (
		<div className="loader-wrapper" sl-test-data="cmpLoader">
			<SlLoader fullScreen backgroundColor={backgroundColor} />
		</div>
	) : null;
});

import React, { useEffect, useState } from 'react';
import { merge, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { useParams } from 'react-router-dom';
import useStyles from 'isomorphic-style-loader/useStyles';

import classNames from 'classnames';
import { AuthService, Container, ModalService } from '../../../../../symphony';

import { HeaderLeftSide } from '../LeftSide/LeftSide';
import { HeaderRightSide } from '../RightSide/RightSide';
import { SlPlaygroundContext } from '../../../playground-context';
import { SlPlaygroundActionsService } from '../../../services/sl-playground-actions.service';
import {
	IModalData,
	IModalStates,
	SlPlaygroundActionTypes,
} from '../../../global-interface';
import {
	IUpdatedCodeTemplate,
	SlPlaygroundCodeService,
} from '../../../services/sl-playground-code.service';

import s from './Header.scss';
import { LanguageRoutes } from '../../../../../shared/public/globalInterfaces/globalInterfaces';
import { IUserCode } from '../../../../../api/public/code-api';

export function Header(): JSX.Element {
	useStyles(s);
	const {
		isPublic$, isDarkMode$, codeData$, modalData$, topLevelDomain$,
	} = Container.take(SlPlaygroundContext, SlPlaygroundCodeService);
	const [codeData, setCodeData] = useState<IUserCode | IUpdatedCodeTemplate>(
		codeData$.value,
	);
	const [isDarkMode, setIsDarkMode] = useState<boolean>(true);
	const [isPublic, setIsPublic] = useState<boolean>(false);

	const { actions$ } = Container.take(
		SlPlaygroundContext,
		SlPlaygroundActionsService,
	);
	const modalService = Container.take('global', ModalService);

	const isTryItYourself: boolean = Container.take(
		SlPlaygroundContext,
		'isTryItYourself',
	);

	useEffect(() => {
		const unsubscriber = new Subject<void>();

		merge(
			modalData$.pipe(
				tap((data: IModalData) => {
					if (data?.state === IModalStates.OPEN) {
						modalService.open('playgroundCodeSavingModal', true);
					} else if (data?.state === 'close') {
						modalService.close('playgroundCodeSavingModal');
					}
				}),
			),
			codeData$.pipe(tap(setCodeData)),
			isDarkMode$.pipe(tap(setIsDarkMode)),
			isPublic$.pipe(tap(setIsPublic)),
		)
			.pipe(takeUntil(unsubscriber))
			.subscribe();
	}, []);

	const language: string | LanguageRoutes = useParams()?.language;

	const onPublishBtnPress = (state: boolean): void => {
		actions$.next({
			type: SlPlaygroundActionTypes.onHeaderPublishCode,
			data: {
				state,
				language,
			},
			requestType: 'parallel',
		});
	};

	const onChangeEditorMode = (state: boolean): void => {
		actions$.next({
			type: SlPlaygroundActionTypes.changeEditorMode,
			data: {
				state,
			},
			requestType: 'parallel',
		});
	};

	const onSaveBtnPress = (): void => {
		actions$.next({
			type: SlPlaygroundActionTypes.onHeaderSaveCode,
			requestType: 'parallel',
			data: {
				language,
			},
		});
	};

	const onRenameBtnPress = (): void => {
		actions$.next({
			type: SlPlaygroundActionTypes.onHeaderRenameCode,
			requestType: 'parallel',
			data: {
				language,
			},
		});
	};

	const onCloseIconPress = (): void => {
		actions$.next({
			type: SlPlaygroundActionTypes.onHeaderCloseIcon,
		});
	};

	const getUserInfoHref = (): string => `${topLevelDomain$.value}/Profile/${codeData.userId}`;

	const getCodeAuthor = (): { avatarUrl: string; name: string } => {
		const userInfo = Container.take('global', AuthService).getUser();
		if (userInfo && codeData?.userId === userInfo?.id) {
			return {
				avatarUrl: userInfo.avatarUrl,
				name: userInfo.name,
			};
		}
		return {
			avatarUrl: codeData?.avatarUrl,
			name: codeData?.userName,
		};
	};

	return (
		<div
			className={classNames('sl-playground-header-wrapper', {
				dark: isDarkMode,
			})}
		>
			<div className="sl-playground-header-wrapper__content">
				<HeaderLeftSide
					onCloseIcon={onCloseIconPress}
					author={getCodeAuthor()}
					title={codeData?.name}
					onRenameBtnPress={onRenameBtnPress}
					isTryItYourself={isTryItYourself}
					isDarkMode={isDarkMode}
					getUserInfoHref={getUserInfoHref}
				/>
				<HeaderRightSide
					language={codeData?.language}
					onSaveBtnClick={onSaveBtnPress}
					onChangePublicState={onPublishBtnPress}
					onChangeEditorMode={onChangeEditorMode}
					isPublic={isPublic}
					// isLogged={isLogged}
					// modalData={modalData}
					isDarkMode={isDarkMode}
				/>
			</div>
		</div>
	);
}

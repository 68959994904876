import { Container, StorageService } from '../../../symphony';

const storage = Container.take('global', StorageService);

export const getIsDark = (): boolean => {
	const isDark = storage.load('isDark', 'local');
	return isDark !== 'false';
};

export const setIsDark = (value: boolean): void => {
	storage.save('isDark', value.toString(), 'local');
};

export const removeIsDark = (): void => {
	storage.remove('isDark', 'local');
};

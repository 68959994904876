import React, {
	CSSProperties,
	memo,
	useEffect,
	useState,
	FC,
	forwardRef,
	RefObject,
} from 'react';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Container } from '../../../../../symphony';

import codeLoaderJson from './code-loader.json';
import { SlPlaygroundContext } from '../../../playground-context';
import { SlPlaygroundCodeService } from '../../../services/sl-playground-code.service';
import { SlAnimationLoader } from '../../../../../shared/public/SlAnimationLoader/SlAnimationLoader';

export interface ICodeLoaderProps {
	styles?: CSSProperties;
}

const CodeLoader: FC<ICodeLoaderProps> = forwardRef(
	({ styles }: ICodeLoaderProps, ref: RefObject<HTMLDivElement>) => {
		const { animationLoader$ } = Container.take(
			SlPlaygroundContext,
			SlPlaygroundCodeService,
		);
		const [loadingState, setLoadingState] = useState<boolean>(false);

		useEffect(() => {
			const unsubscriber = new Subject<void>();

			animationLoader$
				.pipe(takeUntil(unsubscriber))
				.subscribe(setLoadingState);

			return () => {
				unsubscriber.next();
				unsubscriber.complete();
			};
		}, []);

		return (
			<SlAnimationLoader
				show={loadingState}
				animationData={codeLoaderJson}
				styles={styles}
				className="learning"
				ref={ref}
				loop
			/>
		);
	},
);

export default memo(CodeLoader);

import { BehaviorSubject } from 'rxjs';

import { Service } from '../../../../symphony';

import { IAction } from '../../global-interface';

@Service()
export class Actions {
	// Todo rename actionsV2$ to actions$ after implement new service in all areas
	public actionsV2$ = new BehaviorSubject<IAction>(null);
}

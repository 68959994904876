import { Observable } from 'rxjs';

import { take } from 'rxjs/operators';
import { Service } from '../../../symphony';
import {
	CodeApi, DefaultCodeLanguages, ICodeAPI, ICodeCompilationResponse, ICodeCompilePayload, ICodeDefaultResponse, ICodeTemplateResponse, ILanguagesCatalogResponse, IUserCodePayload, IUserCodeResponse, IVoteUserCodePayload, IVoteUserCodeResponse,
} from '../../../api/public/code-api';
import { LanguagesResponseType, StaticPagesAPI } from '../../../api/public/static-pages-api';

@Service()
export class SlPlaygroundApiService {
	private api: ICodeAPI;

	private staticPagesApi: StaticPagesAPI;

	constructor() {
		this.api = new CodeApi();
		this.staticPagesApi = new StaticPagesAPI();
	}

	public getUserCode(id: string): Observable<IUserCodeResponse> {
		return this.api.getUserCode(id);
	}

	public getDefaultCode(
		id: DefaultCodeLanguages,
	): Observable<ICodeDefaultResponse> {
		return this.api.getDefaultCode(id);
	}

	public getTemplate(
		templateId: number,
		courseId: number,
	): Observable<ICodeTemplateResponse> {
		return this.api.getTemplate(templateId, courseId);
	}

	public saveUserCode(data: IUserCodePayload): Observable<IUserCodeResponse> {
		return this.api.saveUserCode(data);
	}

	public publishCode(data: IUserCodePayload): Observable<IUserCodeResponse> {
		return this.api.saveUserCode(data);
	}

	public compileCode(
		data: ICodeCompilePayload,
	): Observable<ICodeCompilationResponse> {
		return this.api.compileCode(data);
	}

	public getLanguagesCatalog(): Observable<ILanguagesCatalogResponse> {
		return this.api.getLanguagesCatalog();
	}

	public getLanguages(): Observable<LanguagesResponseType> {
		return this.staticPagesApi.getLanguages();
	}

	public voteCode(
		payload: IVoteUserCodePayload,
	): Observable<IVoteUserCodeResponse> {
		return this.api.voteUserCode(payload).pipe(take(1));
	}
}

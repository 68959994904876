import React, { useEffect } from 'react';
import { filter, take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { LogoPrimary, DmLogoPrimary } from '@sololearnorg/sol';
import useStyles from 'isomorphic-style-loader/useStyles';
import { Container } from '../../../../symphony';

import { SlPlaygroundCodeService } from '../../services/sl-playground-code.service';
import { SlPlaygroundContext } from '../../playground-context';
import { SlPlaygroundActionsService } from '../../services/sl-playground-actions.service';
import { SlPlaygroundActionTypes } from '../../global-interface';
import { LanguageSelector } from './LanguageSelector/LanguageSelector';
import { useLanguageKey } from '../../utils/hooks/useLanguageKey';
import { RightPart } from './RightPart/RightPart';

import s from './Navigation.scss';
import { useContainerData } from '../../../../shared/public/SlHooks/useContainerData';
import { LocalizedLink } from '../../../../shared/public/LocalizedLink/LocalizedLink';

export const Navigation: React.FC = React.memo(() => {
	useStyles(s);
	const { actions$ } = Container.take(
		SlPlaygroundContext,
		SlPlaygroundActionsService,
	);
	const { isDarkMode$: isDarkMode } = useContainerData(
		SlPlaygroundContext,
		SlPlaygroundCodeService,
		['isDarkMode$'],
		{
			isDarkMode$: true,
		},
	);

	const languageKey = useLanguageKey();

	useEffect(() => {
		const { codeData$ } = Container.take(
			SlPlaygroundContext,
			SlPlaygroundCodeService,
		);
		const unsubscriber = new Subject<void>();
		codeData$
			.pipe(
				takeUntil(unsubscriber),
				filter((d) => !!d),
				take(1),
			)
			.subscribe(() => {
				actions$.next({
					type: SlPlaygroundActionTypes.initNavigation,
					requestType: 'parallel',
					data: {
						language: languageKey?.toLowerCase(),
					},
				});
			});

		return () => {
			unsubscriber.next();
			unsubscriber.complete();
		};
	}, []);

	return (
		<nav
			className={`playground-navbar${isDarkMode ? ' dark' : ' light'}`}
			sl-test-data="cmpNavigation"
		>
			<LocalizedLink
				to="/"
				className="playground-navbar__logo"
				onClick={(e) => {
					e.preventDefault();
					actions$.next({
						type: SlPlaygroundActionTypes.onLogoClick,
					});
				}}
			>
				{isDarkMode ? <DmLogoPrimary /> : <LogoPrimary />}
			</LocalizedLink>
			<LanguageSelector languageKey={languageKey} />
			<RightPart languageKey={languageKey} />
		</nav>
	);
});

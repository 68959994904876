import { BehaviorSubject, Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { AuthService, Container, Service } from '../../../symphony';
import { CodeLanguages } from '../../../api/private/global.interface';

declare const slTrack: (payload: {
	action: string;
	element: string;
	entityId?: number;
	redirectUrl?: string;
	userId?: string;
}) => void;

export interface ITrackAction {
	action: string;
	element: string;
	entityId?: number;
	redirectUrl?: string;
	userId?: string;
}

declare const dataLayer: Array<ITagManagerAction>;

export interface ITagManagerAction {
	event: string;
	userId?: number;
	language?: CodeLanguages;
}

@Service()
export class SlPlaygroundTrackingService {
	public trackAction$ = new BehaviorSubject<ITrackAction>(null);

	public tagManager$ = new BehaviorSubject<ITagManagerAction>(null);

	private authService = Container.take('global', AuthService);

	private fingerprint = Container.take('global', 'fingerprint');

	public trackActionHandler$ = this.trackAction$.pipe(
		filter((a) => !!a),
		tap((action: ITrackAction) => {
			if (typeof window !== 'undefined') {
				slTrack({
					action: action.action,
					element: action.element,
					entityId: action.entityId || null,
					redirectUrl: action.redirectUrl || null,
					userId: action.userId || null,
				});
			}
		}),
	);

	public tagManagerHandler$: Observable<ITagManagerAction> = this.tagManager$.pipe(
		filter((a) => !!a),
		tap((action) => {
			if (typeof window !== 'undefined') {
				dataLayer.push({
					userId: this.authService.getUser()?.id,
					...action,
				});
			}
		}),
	);
}

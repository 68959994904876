import React, { useEffect, useState } from 'react';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { Container } from '../../../../symphony';

import { IFlashMessage } from '../../global-interface';
import { SlPlaygroundContext } from '../../playground-context';
import { SlPlaygroundCodeService } from '../../services/sl-playground-code.service';
import { SlFlashMessage } from '../../../../shared/public/SlFlashMessage/SlFlashMessage';

export function FlashMessage(): JSX.Element {
	const { flashMessage$ } = Container.take(
		SlPlaygroundContext,
		SlPlaygroundCodeService,
	);
	const [flashMessage, setFlashMessage] = useState<IFlashMessage>(null);

	useEffect(() => {
		const unsubscriber = new Subject<void>();

		flashMessage$.pipe(takeUntil(unsubscriber)).subscribe(setFlashMessage);

		return () => {
			unsubscriber.next();
			unsubscriber.complete();
		};
	}, []);

	const didClosed = () => {
		flashMessage$.next(null);
	};

	return flashMessage ? (
		<SlFlashMessage
			data={{ ...flashMessage }}
			didClosed={didClosed}
			sl-test-data="cmpFlashMessage"
		/>
	) : null;
}

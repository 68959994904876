import React, { useEffect, memo } from 'react';

import { Container } from '../../../../symphony';

import {
	IOutput,
	SlPlaygroundCodeService,
} from '../../services/sl-playground-code.service';
import { SlPlaygroundContext } from '../../playground-context';
import { OutputIframe } from '../../../../shared/public/SlCodeEditorComponents/OutputIframe/OutputIframe';
import { useIFrameSrc } from '../../../../shared/public/SlCodeEditorComponents/utils/hooks';

export interface OutputIframeContainerProps {
	value: IOutput;
	isWeb?: boolean;
	isDarkMode?: boolean;
}

export const OutputIframeContainer: React.FC<OutputIframeContainerProps> = memo(
	({ value, isWeb, isDarkMode = true }) => {
		const { outputConsole$ } = Container.take(
			SlPlaygroundContext,
			SlPlaygroundCodeService,
		);

		const iframeSrc = useIFrameSrc();

		useEffect(() => {
			const receiveIframeMessage = (e) => {
				if (e.origin !== iframeSrc) {
					return;
				}
				const data = e.data?.console;

				if (data) {
					outputConsole$.next(
						outputConsole$.value
							? [...outputConsole$.value, data]
							: [data],
					);
				}
			};

			window.addEventListener('message', receiveIframeMessage, false);

			return () => {
				window.removeEventListener(
					'message',
					receiveIframeMessage,
					false,
				);
			};
		}, []);

		// eslint-disable-next-line
        const text = value ? new String(value.output || '') : ''; // for rendering when text is not changed

		useEffect(() => {
			const iframe: any = document.getElementById(
				'output-frame',
			) as HTMLIFrameElement;
			const iframeDoc = iframe.contentWindow;
			outputConsole$.next(null);

			if (iframeDoc) {
				iframeDoc.postMessage(isWeb ? text : value?.output, '*');
			}
		}, [text]);

		return <OutputIframe value={value} isDarkMode={isDarkMode} />;
	},
);

import { ICodeVersions } from '../../features/api/public/code-api';
import { IProfileVersions } from '../../features/api/public/profile-api';

export const userInfoVersions: IProfileVersions = {
	getProfile: 'v3',
	updateInfo: 'v3',
	follow: 'v3',
	unfollow: 'v3',
	getFollowers: 'v3',
	getFollowing: 'v3',
	uploadAvatar: 'v3',
	changePassword: 'v3',
};

export const codeVersions: ICodeVersions = {
	compileCode: 'v2',
};

import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { Container } from '../../../../symphony';
import { ActionType, RequestType } from '../../global-interface';
import { SlPlaygroundContext } from '../../playground-context';
import { SlPlaygroundCodeService } from '../../services/sl-playground-code.service';
import { Actions } from '../../services/ActionsService/actions';
import s from './Notification.scss';
import { useContainerData } from '../../../../shared/public/SlHooks/useContainerData';
import { SlFlashMessage } from '../../../../shared/public/SlFlashMessage/SlFlashMessage';

export function Notification() {
	useStyles(s);
	const { actionsV2$ } = Container.take(SlPlaygroundContext, Actions);
	const { notificationData$: notificationData } = useContainerData(
		SlPlaygroundContext,
		SlPlaygroundCodeService,
		['notificationData$'],
	);

	const closeHandler = () => {
		actionsV2$.next({
			type: ActionType.TriggerNotificationVisibility,
			requestType: RequestType.Parallel,
		});
	};

	return notificationData ? (
		<div className="playground-notification">
			<SlFlashMessage
				data={{
					message: notificationData.message,
					type: notificationData.type,
				}}
				didClosed={closeHandler}
				onClose={closeHandler}
			/>
		</div>
	) : null;
}

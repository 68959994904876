import React, { memo } from 'react';

import { Button } from '@sololearnorg/sol';
import useStyles from 'isomorphic-style-loader/useStyles';

import s from './Modals.scss';

export interface IModalWithoutInputProps {
	showDiscardButton?: boolean;
	onDiscardButtonPress?: () => void;
	onSaveBtnPress?: () => void;
	title?: string;
	height?: string;
	width?: string;
}

export const ModalWithoutInput: React.FC<IModalWithoutInputProps> = memo(
	({
		showDiscardButton,
		onDiscardButtonPress,
		onSaveBtnPress,
		title,
		height = '115px',
		width = '478px',
	}) => {
		useStyles(s);

		return (
			<div
				className="sl-modal-code-naming"
				style={{
					height,
					width,
				}}
			>
				<p className="sl-modal-code-naming__title">{title}</p>
				<div className="sl-modal-code-naming__margins sl-modal-code-naming__flex-row">
					{showDiscardButton && (
						<div
							onClick={onDiscardButtonPress}
							className="sl-modal-code-naming__discard"
						>
							Discard
						</div>
					)}
					<Button
						onClick={onSaveBtnPress}
						size="s"
						style={{
							width: '125px',
						}}
					>
						Save
					</Button>
				</div>
			</div>
		);
	},
);

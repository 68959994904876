import React, { useCallback, useEffect, useMemo } from 'react';
import { Subject } from 'rxjs';
import { useParams } from 'react-router-dom';

import useStyles from 'isomorphic-style-loader/useStyles';
import { Container, history } from '../../../../symphony';

import { SlPlaygroundActionsService } from '../../services/sl-playground-actions.service';
import { SlPlaygroundCodeService } from '../../services/sl-playground-code.service';
import { LANGUAGE_TYPES } from '../../constants/LanguageTypes';
import { FlashMessage } from '../../components/FlashMessage/FlashMessage';
import { FreezeLayer } from '../../components/FreezeLayer/FreezeLayer';
import { ModalsWrapper } from '../../components/Modals/ModalsWrapper';
import { SlPlaygroundActionTypes } from '../../global-interface';
import { SlPlaygroundContext } from '../../playground-context';
import { Header } from '../../components/Header/Header/Header';
import { Loader } from '../../components/Loader/Loader';
import { NotWeb } from '../NotWeb/NotWeb';
import { Web } from '../Web/Web';
import { useLeave } from '../../components/HOOKS/useLeave';
import { Navigation } from '../../components/Navigation/Navigation';
import { useLanguageKey } from '../../utils/hooks/useLanguageKey';
import s from './PlaygroundContainer.scss';
import { Notification } from '../../components/Notification/Notification';
import { LanguageNames, LanguageRoutes } from '../../../../shared/public/globalInterfaces/globalInterfaces';
import { useComponentWillMount } from '../../../../shared/public/SlUtils/useComponentWillMount';
import { useContainerData } from '../../../../shared/public/SlHooks/useContainerData';
import { useConfirmLeave } from '../../../../shared/public/SlHooks/useConfirmLeave';
import { SlHelmet } from '../../../../shared/public/SlHelmet/SlHelmet';
import { CodeLanguages } from '../../../../api/private/global.interface';
import { Footer } from '../../components/Footer/Footer';

export interface SlSlPlaygroundContainerProps {
	language?: LanguageRoutes | string | CodeLanguages;
	onClose?: () => void;
	isTIY?: boolean;
}

export function PlaygroundContainer({
	language: propsLanguage,
	onClose,
	isTIY,
}: SlSlPlaygroundContainerProps): JSX.Element {
	useStyles(s);
	const language = useParams()?.language || propsLanguage;
	const languageKey = useLanguageKey();
	const appName = Container.take('global', 'appName');

	const { actions$ } = Container.take(
		SlPlaygroundContext,
		SlPlaygroundActionsService,
	);

	useComponentWillMount(() => {
		actions$.next({
			type: SlPlaygroundActionTypes.getCodeData,
			data: {
				language,
			},
			requestType: 'parallel',
		});
	});

	const {
		isWeb$: isWeb,
		codeData$: codeData,
		showConfirmLeaveAlert$: showConfirmLeaveAlert,
	} = useContainerData(SlPlaygroundContext, SlPlaygroundCodeService, [
		'isWeb$',
		'codeData$',
		'showConfirmLeaveAlert$',
	]);

	const handleClose = () => {
		if (onClose) {
			onClose();
			return;
		}

		const logoutHandler: () => void = Container.take(
			SlPlaygroundContext,
			'logoutHandler',
		);

		if (logoutHandler) {
			logoutHandler();
			return;
		}

		const afterCloseRedirectRoute = Container.take(
			SlPlaygroundContext,
			'afterCloseRedirectRoute',
		) as string;
		history.push(afterCloseRedirectRoute);
		// window.onbeforeunload = null;
		// window.location.href = afterCloseRedirectRoute;
	};

	const getLanguageType = useMemo(() => (
		LANGUAGE_TYPES.find((item) => item.path === language)
		|| LANGUAGE_TYPES.find((item) => item.language === codeData?.language)
	), [language, codeData?.language]);

	const getContent = useCallback(() => {
		if (isWeb && codeData) {
			return <Web language={getLanguageType?.language} />;
		}
		return <NotWeb languageType={getLanguageType} />;
	}, [isWeb, codeData]);

	useEffect(() => {
		const unsubscriber = new Subject<void>();

		Container.register(SlPlaygroundContext, 'onClose', handleClose);

		actions$.next({
			type: SlPlaygroundActionTypes.initPlayground,
			requestType: 'parallel',
		});

		return () => {
			actions$.next({
				type: SlPlaygroundActionTypes.resetContainer,
			});
			unsubscriber.next();
			unsubscriber.complete();
		};
	}, []);

	useConfirmLeave(showConfirmLeaveAlert);

	useLeave(
		showConfirmLeaveAlert,
		'Are you sure to leave? Your changes may not be saved.',
	);

	const generateMetaTitle = () => {
		const codeMetaName = codeData?.name ? ` - ${codeData.name}` : '';
		let metaTitle = '';
		if (languageKey === 'WEB') {
			metaTitle = `HTML/CSS${codeMetaName} Online Editor and JavaScript Compiler`;
		} else {
			metaTitle = `${LanguageNames[languageKey]} Playground${codeMetaName}: Online Interpreter, Compiler & Editor`;
		}
		return `${metaTitle} | ${appName}`;
	};

	return (
		<>
			{languageKey && (
				<SlHelmet
					description={getLanguageType?.metaTag?.description}
					title={generateMetaTitle()}
				/>
			)}
			<div
				className="sl-playground-container"
				sl-test-data="cmpPlaygroundContainer"
			>
				{!isTIY && <Navigation />}
				<div className="sl-playground-container__header">
					<Header />
				</div>
				<div
					className="sl-playground-container__content"
				>
					{typeof window !== 'undefined' && getContent()}
				</div>
				<div className="sl-playground-container__footer">
					<Footer />
				</div>
				<Loader backgroundColor={codeData ? undefined : 'white'} />
				<ModalsWrapper handleClose={handleClose} />
				<FlashMessage />
				<FreezeLayer />
				<Notification />
			</div>
		</>
	);
}

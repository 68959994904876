import { useParams } from 'react-router-dom';

import { LANGUAGE_TYPES } from '../../constants/LanguageTypes';
import { SlPlaygroundContext } from '../../playground-context';
import { SlPlaygroundCodeService } from '../../services/sl-playground-code.service';
import { useContainerData } from '../../../../shared/public/SlHooks/useContainerData';
import { LanguageRoutes } from '../../../../shared/public/globalInterfaces/globalInterfaces';
import { CodeLanguages } from '../../../../api/private/global.interface';

export const useLanguageKey = () => {
	const { codeData$: codeData } = useContainerData(
		SlPlaygroundContext,
		SlPlaygroundCodeService,
		['codeData$'],
	);
	const language = useParams()?.language;
	const path = (
		LANGUAGE_TYPES.find((item) => item.path === language)
		|| LANGUAGE_TYPES.find((item) => {
			if (codeData?.language === CodeLanguages.HTML) {
				return item.language === CodeLanguages.WEB;
			}
			return item.language === codeData?.language;
		})
	)?.path;

	const indexOfLanguage = Object.values(LanguageRoutes).indexOf(
		path as unknown as LanguageRoutes,
	);
	return Object.keys(LanguageRoutes)[indexOfLanguage];
};

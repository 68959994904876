import React from 'react';

import { IconAvatarSmall } from '@sololearnorg/sol';
import { i18n } from '../../../../../symphony';
import { LocalizedLink } from '../../../../../shared/public/LocalizedLink/LocalizedLink';

export interface IUserInfoProps {
	avatarUrl: string;
	authorName: string;
	isDarkMode?: boolean;
	href: string;
}

export const UserInfo: React.FC<IUserInfoProps> = React.memo(
	({
		avatarUrl, authorName = '', isDarkMode, href,
	}) => (
		<LocalizedLink
			to={href}
			className="sl-header-user-info"
			target="_blank"
		>
			{avatarUrl ? (
				<img
					className="sl-header-user-info__avatar"
					src={avatarUrl}
					alt="avatar"
				/>
			) : (
				<div className="sl-header-user-info__default-avatar-icon">
					<IconAvatarSmall size={25} />
				</div>
			)}
			{authorName && (
				<span
					style={{
						color: isDarkMode ? '#c8d2db' : '#2d3846',
					}}
					className="sl-header-user-info__name"
				>
					{i18n.t('web-playground.author-label')}
					{` ${authorName}`}
				</span>
			)}
		</LocalizedLink>
	),
);

import { useEffect } from 'react';
import { history as browserHistory } from '../../../../symphony';

export const useLeave = (hasChanges: boolean, message: string): JSX.Element => {
	useEffect(() => {
		if (history && history.pushState && history.state && hasChanges) {
			const currentLocation = location.href;
			window.onpopstate = () => {
				if (
				// eslint-disable-next-line no-alert
					confirm(message)
				) {
					window.onpopstate = null;
				} else {
					browserHistory.push(currentLocation);
				}
			};
		}

		if (!hasChanges && window.onpopstate) {
			window.onpopstate = null;
		}
	}, [hasChanges]);

	useEffect(() => () => {
		window.onpopstate = null;
	}, []);
	return null;
};

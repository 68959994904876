import React, { useRef } from 'react';

import { Avatar } from '@sololearnorg/sol';
import useStyles from 'isomorphic-style-loader/useStyles';
import { AuthService, Container } from '../../../../../symphony';

import { SlPlaygroundContext } from '../../../playground-context';
import { SlPlaygroundCodeService } from '../../../services/sl-playground-code.service';
import { UserMenu } from './UserMenu';
import { SlPlaygroundActionTypes } from '../../../global-interface';
import { SlPlaygroundActionsService } from '../../../services/sl-playground-actions.service';

import s from './UserProfile.scss';
import { useContainerData } from '../../../../../shared/public/SlHooks/useContainerData';
import { useOutsideClick } from '../../../../../shared/public/SlHooks/useOutsideClick';

export function UserProfile(): JSX.Element {
	useStyles(s);
	const element = useRef<HTMLDivElement>(null);
	const authService = Container.take('global', AuthService);
	const user = authService.getUser();

	const { actions$ } = Container.take(
		SlPlaygroundContext,
		SlPlaygroundActionsService,
	);

	const { isUserMenuOpen$: isUserMenuOpen } = useContainerData(
		SlPlaygroundContext,
		SlPlaygroundCodeService,
		['isUserMenuOpen$'],
	);

	useOutsideClick(element, isUserMenuOpen, !isUserMenuOpen, () => actions$.next({
		type: SlPlaygroundActionTypes.closeUserMenu,
		requestType: 'parallel',
	}));

	const onAvatarClick = (e) => {
		e.stopPropagation();
		actions$.next({
			type: SlPlaygroundActionTypes.onAvatarClick,
			requestType: 'parallel',
		});
	};

	const onUserPhotoClick = (): void => {
		actions$.next({
			type: SlPlaygroundActionTypes.onUserPhotoClick,
			requestType: 'parallel',
		});
	};

	const userPhotoWrapper = () => (
		<div
			className={`playground-user__image-wrapper${
				isUserMenuOpen ? ' active' : ''
			}`}
			sl-test-data="cmpUserImageWrapper"
			onClick={onUserPhotoClick}
		>
			{user?.avatarUrl ? (
				<img
					className="playground-user__select__image"
					src={user.avatarUrl}
					alt="user-avatar"
				/>
			) : (
				<div className="playground-user__select__image">
					<Avatar pro={user?.isPro} size="s" />
				</div>
			)}
		</div>
	);

	return (
		<div
			className="playground-user"
			ref={element}
			sl-test-data="cmpUserProfile"
		>
			<div onMouseDown={onAvatarClick} sl-test-data="cmpUserProfilePhoto">
				{userPhotoWrapper()}
			</div>
			{isUserMenuOpen && <UserMenu user={user} />}
		</div>
	);
}

import { BehaviorSubject, of, merge } from 'rxjs';
import { filter, switchMap, mergeMap } from 'rxjs/operators';
import { Container, Service } from '../../../symphony';

import { SlPlaygroundContext } from '../playground-context';
import {
	IGetCodeDataHandlerArgs,
	SlPlaygroundActionHandlersService,
} from './sl-playground-action-handlers.service';
import {
	RequestType,
	SlPlaygroundActionTypes,
	TouchPoint,
	UserMenuItemName,
} from '../global-interface';
import { ISlLoginResponse } from '../../../login/private/global.interface';
import { LanguageRoutes } from '../../../shared/public/globalInterfaces/globalInterfaces';
import { CodeLanguages } from '../../../api/private/global.interface';
import { DefaultCodeLanguages, ICodeCompilePayload, IUserCodePayload } from '../../../api/public/code-api';

export interface ISlPlaygroundAction {
	type: SlPlaygroundActionTypes;
	data?: {
		codeId?: string;
		templateId?: number;
		code?: string;
		language?: CodeLanguages | LanguageRoutes | string;
		input?: string;
		saveCodePayload?: IUserCodePayload;
		compileCodePayload?: ICodeCompilePayload;
		jsCode?: string;
		cssCode?: string;
		sourceCode?: string;
		name?: string;
		isPublic?: boolean;
		defaultCodeLanguage?: DefaultCodeLanguages;
		id?: number;
		callback?: () => void;
		publicId?: string;
		hasLanguageTypeScreen?: boolean;
		errorHandler?: { [key: string]: () => void; };
		state?: boolean;
		topLevelDomain?: string;
		courseRoute?: string;
		languageRoute?: string;
		userMenuItemName?: UserMenuItemName;
		touchPoint?: TouchPoint;
		loginResponse?: ISlLoginResponse;
	};
	requestType?: 'cancelable' | 'parallel' | 'sequential' | RequestType;
}

@Service()
export class SlPlaygroundActionsService {
	private handlers = Container.take(
		SlPlaygroundContext,
		SlPlaygroundActionHandlersService,
	);

	public actions$ = new BehaviorSubject<ISlPlaygroundAction>(null);

	private parallel = this.actions$.pipe(
		filter((a) => a && a.requestType === 'parallel'),
		mergeMap((action) => {
			switch (action.type) {
				case SlPlaygroundActionTypes.initApp:
					this.handlers.initAppHandler(action.data.topLevelDomain);
					break;
				case SlPlaygroundActionTypes.initPlayground:
					this.handlers.initPlaygroundHandler();
					break;
				case SlPlaygroundActionTypes.getCodeData:
					return this.handlers.getCodeDataHandler(
						action.data as IGetCodeDataHandlerArgs,
					);
				case SlPlaygroundActionTypes.changeEditorMode:
					this.handlers.changeEditorModeHandler(action.data.state);
					break;
				case SlPlaygroundActionTypes.onHeaderPublishCode:
					return this.handlers.onHeaderPublishCodeHandler(
						action.data.state,
						action.data.language as CodeLanguages,
					);
				case SlPlaygroundActionTypes.onHeaderSaveCode:
					return this.handlers.onHeaderSaveCodeHandler(
						action.data.language as CodeLanguages,
					);
				case SlPlaygroundActionTypes.onHeaderRenameCode:
					this.handlers.onHeaderRenameCodeHandler(
						action.data.language as CodeLanguages,
					);
					break;
				case SlPlaygroundActionTypes.toggleConsole:
					return this.handlers.toggleConsole();
				case SlPlaygroundActionTypes.openConsole:
					return this.handlers.openConsole();
				case SlPlaygroundActionTypes.SlTiyRunCall:
					return this.handlers.SlTiyRunCall();
				case SlPlaygroundActionTypes.onUserMenuItemClick:
					return this.handlers.onUserMenuItemClickHandler({
						userMenuItemName: action.data.userMenuItemName,
					});
				case SlPlaygroundActionTypes.onAvatarClick:
					return this.handlers.onAvatarClickHandler();
				case SlPlaygroundActionTypes.onUserPhotoClick:
					return this.handlers.onUserPhotoClickHandler();
				case SlPlaygroundActionTypes.closeUserMenu:
					return this.handlers.closeUserMenuHandler();
				case SlPlaygroundActionTypes.onStartCourseClick:
					return this.handlers.onStartCourseClickHandler(
						action.data.language as CodeLanguages,
					);
				case SlPlaygroundActionTypes.onRegisterClick:
					return this.handlers.onRegisterClickHandler(
						action.data.language as CodeLanguages,
					);
				case SlPlaygroundActionTypes.onLanguageSelectClick:
					return this.handlers.onLanguageSelectClickHandler(
						action.data.language as CodeLanguages,
					);
				case SlPlaygroundActionTypes.initNavigation:
					return this.handlers.initNavigationHandler(
						action.data.language as CodeLanguages,
					);
				case SlPlaygroundActionTypes.openLoginModal:
					return this.handlers.openLoginModalHandler({
						touchPoint: action.data.touchPoint,
						language: action.data.language as CodeLanguages,
					});
				default:
					return of(action);
			}
			return of(action);
		}),
	);

	public cancelable = this.actions$.pipe(
		filter((a) => a && (!a.requestType || a.requestType === 'cancelable')),
		switchMap((action: ISlPlaygroundAction) => {
			switch (action.type) {
				case SlPlaygroundActionTypes.getDefaultCode:
					return this.handlers.getDefaultCodeHandler(
						action.data.defaultCodeLanguage,
					);
				case SlPlaygroundActionTypes.getUserCode:
					return this.handlers.getUserCodeHandler(action.data.codeId);
				case SlPlaygroundActionTypes.getTemplate:
					return this.handlers.getTemplateHandler(
						action.data.templateId,
						action.data.errorHandler,
					);
				case SlPlaygroundActionTypes.saveUserCode:
					return this.handlers.saveUserCodeHandler({
						sourceCode: action.data.sourceCode,
						jsCode: action.data.jsCode,
						cssCode: action.data.cssCode,
						language: action.data.language as CodeLanguages,
						name: action.data.name,
						isPublic: action.data.isPublic,
						id: action?.data?.id,
					});
				case SlPlaygroundActionTypes.publishCode:
					return this.handlers.publishCodeHandler({
						sourceCode: action.data.sourceCode,
						jsCode: action.data.jsCode,
						cssCode: action.data.cssCode,
						language: action.data.language as CodeLanguages,
						name: action.data.name,
						isPublic: action.data.isPublic,
						id: action?.data?.id,
					});
				case SlPlaygroundActionTypes.compileCode:
					return this.handlers.compileCodeHandler({
						code: action.data.code,
						language: action.data.language as CodeLanguages,
						input: action.data.input,
						codeId: action.data.codeId,
					});
				case SlPlaygroundActionTypes.compileWebCode:
					this.handlers.compileWebCodeHandler(
						action.data.sourceCode,
						action.data.cssCode,
						action.data.jsCode,
					);
					break;

				case SlPlaygroundActionTypes.resetContainer:
					this.handlers.resetContainerHandler();
					break;

				case SlPlaygroundActionTypes.onHeaderCompileCode:
					return this.handlers.onHeaderCompileCodeHandler();

				case SlPlaygroundActionTypes.onHeaderCloseIcon:
					this.handlers.onHeaderCloseIconHandler();
					break;

				case SlPlaygroundActionTypes.onLogoClick:
					this.handlers.onLogoClickHandler();
					break;

				case SlPlaygroundActionTypes.onCourseClick:
					this.handlers.onCourseClickHandler(action.data.courseRoute);
					break;

				case SlPlaygroundActionTypes.onLanguageChange:
					this.handlers.onLanguageChangeHandler(
						action.data.languageRoute,
					);
					break;

				case SlPlaygroundActionTypes.onModalCompileCode:
					return this.handlers.onModalCompileCodeHandler();
				case SlPlaygroundActionTypes.makeFreeze:
					this.handlers.makeFreezeHandler();
					break;
				default:
					return of(null);
			}
			return of(null);
		}),
	);

	constructor() {
		merge(this.cancelable, this.parallel)
			.pipe(filter((a) => !!a))
			.subscribe();
	}
}

import React, { FC, memo, useMemo } from 'react';
import { editor } from 'monaco-editor';
import useStyles from 'isomorphic-style-loader/useStyles';

import s from './EditorWithLabel.scss';
import { SL_CODE_SUPPORTED_THEMES } from '../../../../code/public/global/constants';
import { CodeLanguages } from '../../../../api/private/global.interface';
import { EditorLabels } from '../../../../shared/public/SlCodeEditorComponents/EditorLabels/EditorLabels';
import { CodeLanguagesToProgrammingLanguage } from '../../../../shared/public/constants/constants';

export interface IEditorWithLabelProps {
	language: CodeLanguages;
	value?: string;
	codeEditorRef?: any;
	theme?: SL_CODE_SUPPORTED_THEMES;
	onChange?: (value: string, type?: CodeLanguages) => void;
	options?: editor.IStandaloneEditorConstructionOptions;
	isDarkMode?: boolean;
}

export const EditorWithLabel: FC<IEditorWithLabelProps> = memo(
	({
		language, value, onChange, options, isDarkMode, codeEditorRef,
	}) => {
		useStyles(s);
		const isNode = typeof window === 'undefined';
		const { SlCodeEditor } = useMemo(
			// eslint-disable-next-line global-require
			() => (!isNode ? require('../../../../code/public/SlCodeEditor/SlCodeEditor') : {}),
			[],
		);

		return (
			<div className="sl-editorWithLabel-wrapper">
				{language && (
					<>
						<EditorLabels isDarkMode={isDarkMode} languages={[CodeLanguagesToProgrammingLanguage[language]]} />
						<SlCodeEditor
							ref={codeEditorRef}
							height="calc(100% - 31px)"
							width="calc(100% + 1px)"
							paddingBeforeFirstLine={1}
							value={value}
							isLightMode={!isDarkMode}
							language={language}
							onChange={({ value, lng }) => onChange(value, lng)}
							options={options}
							theme={
								isDarkMode
									? SL_CODE_SUPPORTED_THEMES.DARK
									: SL_CODE_SUPPORTED_THEMES.LIGHT
							}
						/>
					</>
				)}
			</div>
		);
	},
);

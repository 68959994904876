import React from 'react';

import { IconHelpCircle, IconLogout, IconSettings } from '@sololearnorg/sol';
import { Container, IUser, i18n } from '../../../../../symphony';

import { SlPlaygroundContext } from '../../../playground-context';
import { SlPlaygroundActionsService } from '../../../services/sl-playground-actions.service';
import {
	SlPlaygroundActionTypes,
	UserMenuItemName,
} from '../../../global-interface';

interface IUserMenu {
	user: IUser;
}

export function UserMenu({ user }: IUserMenu): JSX.Element {
	const onUserMenuItemClick = (itemName: UserMenuItemName) => {
		const { actions$ } = Container.take(
			SlPlaygroundContext,
			SlPlaygroundActionsService,
		);

		actions$.next({
			requestType: 'parallel',
			type: SlPlaygroundActionTypes.onUserMenuItemClick,
			data: {
				userMenuItemName: itemName,
			},
		});
	};

	return (
		<div
			onClick={(e) => e.stopPropagation()}
			className="playground-user__menu-settings"
			sl-test-data="cmpUserMenu"
		>
			<div className="playground-user__menu-settings__mobile-menu">
				<div
					onClick={() => onUserMenuItemClick(UserMenuItemName.Profile)}
					sl-test-data="cmpUserMenuProfileItem"
					className="playground-user__menu-settings__name"
				>
					<span className="playground-user__menu-settings__name__title">
						{user?.name}
					</span>
					<p className="playground-user__menu-settings__name__goto">
						{i18n.t('web-playground.profile-link')}
					</p>
				</div>
			</div>
			<div className="playground-user__menu-settings__line" />
			<div className="playground-user__menu-settings__profile">
				<div
					onClick={() => onUserMenuItemClick(UserMenuItemName.Settings)}
					sl-test-data="cmpUserMenuSettingsItem"
					className="playground-user__menu-settings__profile__settings playground-user__menu-settings__profile--item"
				>
					<div className="playground-user__menu-settings__profile__settings__icon">
						<IconSettings />
					</div>
					<p>{i18n.t('web-playground.profile-settings')}</p>
				</div>
				<div
					onClick={() => onUserMenuItemClick(UserMenuItemName.Help)}
					sl-test-data="cmpUserMenuHelpItem"
					className="playground-user__menu-settings__profile__help playground-user__menu-settings__profile--item"
				>
					<div className="playground-user__menu-settings__profile__help__icon">
						<IconHelpCircle />
					</div>
					<p>{i18n.t('web-playground.profile-help')}</p>
				</div>
				<div
					onClick={() => onUserMenuItemClick(UserMenuItemName.Logout)}
					sl-test-data="cmpUserMenuLogoutItem"
					className="playground-user__menu-settings__profile__logout playground-user__menu-settings__profile--item"
				>
					<div className="playground-user__menu-settings__profile__logout__icon">
						<IconLogout />
					</div>
					<p>{i18n.t('web-playground.profile-logout')}</p>
				</div>
			</div>
		</div>
	);
}

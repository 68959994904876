import { CodeLanguages } from '../../../api/private/global.interface';
import { LanguageRoutes } from '../../../shared/public/globalInterfaces/globalInterfaces';

export interface ILanguageType {
	color: string;
	boxShadow: string;
	language: CodeLanguages;
	title: string;
	path: LanguageRoutes;
	show: boolean;
	label?: string;
	metaTag?: {
		description: string;
	};
}

export const LANGUAGE_TYPES: ILanguageType[] = [
	{
		color: '#E44D27',
		boxShadow: 'rgba(229, 77, 38, 0.45)',
		title: 'HTML',
		language: CodeLanguages.HTML,
		label: 'HTML',
		path: LanguageRoutes.HTML,
		show: true,
		metaTag: {
			description:
				'Use our FREE HTML online compiler to write, run and share your code. Works directly from your browser without any additional installation.',
		},
	},
	{
		color: '#379AD6',
		boxShadow: 'rgba(33, 150, 243, 0.45)',
		title: 'CSS',
		language: CodeLanguages.CSS,
		label: 'CSS',
		path: LanguageRoutes.CSS,
		show: true,
		metaTag: {
			description:
				'Use our FREE CSS online compiler to write, run and share your code. Works directly from your browser without any additional installation.',
		},
	},
	{
		color: '#E3B423',
		boxShadow: 'rgba(250, 151, 34, 0.45)',
		title: 'JavaScript',
		language: CodeLanguages.JS,
		label: 'JavaScript',
		path: LanguageRoutes.JAVASCRIPT,
		show: true,
		metaTag: {
			description:
				'Use our FREE JavaScript online compiler to write, run and share your code. Works directly from your browser without any additional installation.',
		},
	},
	{
		color: '#0C6291',
		boxShadow: 'rgba(12, 98, 145, 0.45)',
		title: 'C++',
		language: CodeLanguages.CPP,
		path: LanguageRoutes.CPP,
		label: 'CPP',
		show: true,
		metaTag: {
			description:
				'Use our FREE C++ online compiler to write, run and share your code. Works directly from your browser without any additional installation.',
		},
	},
	{
		color: '#6085BC',
		boxShadow: 'rgba(96, 133, 188, 0.45)',
		title: 'PHP',
		language: CodeLanguages.PHP,
		path: LanguageRoutes.PHP,
		label: 'PHP',
		show: true,
		metaTag: {
			description:
				'Use our FREE PHP online compiler to write, run and share your code. Works directly from your browser without any additional installation.',
		},
	},
	{
		color: '#FA9722',
		boxShadow: 'rgba(250, 151, 34, 0.45)',
		title: 'Java',
		language: CodeLanguages.JAVA,
		path: LanguageRoutes.JAVA,
		label: 'JAVA',
		show: true,
		metaTag: {
			description:
				'Use our FREE Java online compiler to write, run and share your code. Works directly from your browser without any additional installation.',
		},
	},
	{
		color: '#8DB93E',
		boxShadow: 'rgba(141, 185, 62, 0.45)',
		title: 'Node js',
		language: CodeLanguages.NODEJS,
		path: LanguageRoutes.NODEJS,
		label: 'NODE',
		show: true,
		metaTag: {
			description:
				'Use our FREE Node.JS online compiler to write, run and share your code. Works directly from your browser without any additional installation.',
		},
	},
	{
		color: '#652F91',
		boxShadow: 'rgba(101, 47, 145, 0.45)',
		title: 'C#',
		language: CodeLanguages.CSharp,
		path: LanguageRoutes.CSharp,
		label: 'CS',
		show: true,
		metaTag: {
			description:
				'Use our FREE C# online compiler to write, run and share your code. Works directly from your browser without any additional installation.',
		},
	},
	{
		color: '#E04E3F',
		boxShadow: 'rgba(224, 78, 63, 0.45)',
		title: 'Ruby',
		language: CodeLanguages.RUBY,
		path: LanguageRoutes.RUBY,
		label: 'RB',
		show: true,
		metaTag: {
			description:
				'Use our FREE Ruby online compiler to write, run and share your code. Works directly from your browser without any additional installation.',
		},
	},
	{
		color: '#2196F3',
		boxShadow: 'rgba(33, 150, 243, 0.45)',
		title: 'C',
		language: CodeLanguages.C,
		path: LanguageRoutes.C,
		label: 'C',
		show: true,
		metaTag: {
			description:
				'Use our FREE C online compiler to write, run and share your code. Works directly from your browser without any additional installation.',
		},
	},
	{
		color: '#F25F33',
		boxShadow: 'rgba(242, 95, 51, 0.45)',
		title: 'Swift',
		language: CodeLanguages.SWIFT,
		path: LanguageRoutes.SWIFT,
		label: 'SWIFT',
		show: true,
		metaTag: {
			description:
				'Use our FREE Swift online compiler to write, run and share your code. Works directly from your browser without any additional installation.',
		},
	},
	{
		color: '#3396D7',
		boxShadow: 'rgba(51, 150, 215, 0.45)',
		title: 'Kotlin',
		language: CodeLanguages.KOTLIN,
		path: LanguageRoutes.KOTLIN,
		label: 'KT',
		show: true,
		metaTag: {
			description:
				'Use our FREE Kotlin online compiler to write, run and share your code. Works directly from your browser without any additional installation.',
		},
	},
	{
		color: '#134C7A',
		boxShadow: 'rgba(19, 76, 122, 0.45)',
		title: 'Python',
		language: CodeLanguages.PYTHON,
		path: LanguageRoutes.PYTHON,
		label: 'PY',
		show: true,
		metaTag: {
			description:
				'Use our FREE Python online compiler to write, run and share your code. Works directly from your browser without any additional installation.',
		},
	},
	{
		color: '#4162AB',
		boxShadow: 'rgba(65, 98, 171, 0.45)',
		title: 'jQuery',
		language: CodeLanguages.JQUERY,
		path: LanguageRoutes.JQUERY,
		show: true,
		metaTag: {
			description:
				'Use our FREE HTML/CSS/jQuery online editor to write, run and share your code. Works directly from your browser without any additional installation.',
		},
	},
	{
		color: '#4162AB',
		boxShadow: 'rgba(65, 98, 171, 0.45)',
		title: 'sql',
		language: CodeLanguages.SQL,
		label: 'SQL',
		path: LanguageRoutes.SQL,
		show: false,
	},
	{
		color: '#00ACD7',
		boxShadow: 'rgba(65, 98, 171, 0.45)',
		title: 'Go',
		language: CodeLanguages.GO,
		label: 'GO',
		path: LanguageRoutes.GO,
		show: true,
		metaTag: {
			description:
				'Use our FREE Golang online compiler to write, run and share your code. Works directly from your browser without any additional installation.',
		},
	},
	{
		color: '#2066B8',
		boxShadow: 'rgba(65, 98, 171, 0.45)',
		title: 'R',
		language: CodeLanguages.R,
		label: 'R',
		path: LanguageRoutes.R,
		show: true,
		metaTag: {
			description:
				'Use our FREE R online compiler to write, run and share your code. Works directly from your browser without any additional installation.',
		},
	},
	{
		color: '#2D79C7',
		boxShadow: 'rgba(33, 150, 243, 0.45)',
		title: 'TypeScript',
		language: CodeLanguages.TS,
		label: 'TS',
		path: LanguageRoutes.TS,
		show: true,
		metaTag: {
			description:
				'Use our FREE Typescript online compiler to write, run and share your code. Works directly from your browser without any additional installation.',
		},
	},
	{
		color: '#E54D26',
		boxShadow: 'rgba(229, 77, 38, 0.45)',
		title: 'Web',
		language: CodeLanguages.WEB,
		path: LanguageRoutes.WEB,
		show: true,
		metaTag: {
			description:
				'Use our FREE HTML/CSS/JavaScript online editor to write, run and share your code. Works directly from your browser without any additional installation.',
		},
	},
];

import { CodeLanguages } from '../../../api/private/global.interface';

export const InputRegexes = {
	[CodeLanguages.CPP]:
        /(cin\s*>>)|(cin\.getline\s*\([a-zA-Z]*,[0-9]*(,[a-zA-Z]*)?\))|((std::)?getline\s*\((std::)?(cin))/,
	[CodeLanguages.CSharp]: /(Console)[\s\S]*Read(Line)?[\s\S]*\(/,
	[CodeLanguages.JAVA]:
        /((Scanner[\s\S]*\([\s\S]*System\.in[\s\S]*\))[\s\S]*(next))|((InputStreamReader[\s\S]*\([\s\S]*System\.in[\s\S]*\))[\s\S]*(read(Line)?[\s\S]*\())/,
	[CodeLanguages.PYTHON]: /([^a-zA-Z_0-9]input[\s\S]*\()|(^input[\s\S]*\()/,
	[CodeLanguages.RUBY]: /([^a-zA-Z_0-9]gets)/, // ,
	[CodeLanguages.SWIFT]: /\breadLine\b\(/,
	[CodeLanguages.KOTLIN]:
        /readLine\s*\(|nextInts\s*\(|Scanner\s*\(\s*System\.in\s*\)[\s\S]*next|InputStreamReader\s*\(\s*System\.in\s*\)[\s\S]*read(?:Line)?\(/,
	[CodeLanguages.C]: /getchar\s*\(|gets\s*\(|scanf\s*\(|fgets\s*\(/,
	[CodeLanguages.GO]:
        /ReadString[\s]*?\(|ReadLine[\s]*?\(|Scanln[\s]*?\(|ReadRune[\s]*?\(|Scan[\s]*?\(|ReadAll[\s]*?\(|Scanf[\s]*?\(/,
	[CodeLanguages.R]: /(readLines[\s]*?\()/g,
};

import React from 'react';

import useStyles from 'isomorphic-style-loader/useStyles';
import { AuthService, Container, i18n } from '../../../../symphony';

import { SlPlaygroundContext } from '../../playground-context';
import { SlPlaygroundTrackingService } from '../../services/sl-playground-tracking.service';
import { ActionType, RequestType } from '../../global-interface';
import { Actions } from '../../services/ActionsService/actions';
import { SlPlaygroundCodeService } from '../../services/sl-playground-code.service';
import { LanguagesList } from './LanguagesList';
import s from './LanguageTypes.scss';
import { useComponentWillMount } from '../../../../shared/public/SlUtils/useComponentWillMount';
import { useContainerData } from '../../../../shared/public/SlHooks/useContainerData';
import { SlHelmet } from '../../../../shared/public/SlHelmet/SlHelmet';
import { SlFooter } from '../../../../navigation/public/SlFooter/SlFooter';

export function LanguageTypes() {
	useStyles(s);
	const { trackAction$ } = Container.take(
		SlPlaygroundContext,
		SlPlaygroundTrackingService,
	);
	const { actionsV2$ } = Container.take(SlPlaygroundContext, Actions);
	const userId = Container.take('global', AuthService)
		.getUser()
		?.id.toString();

	useComponentWillMount(() => {
		actionsV2$.next({
			type: ActionType.OnLanguageTypesMount,
			requestType: RequestType.Parallel,
		});

		trackAction$.next({
			element: 'codeplayground_codes_selection',
			action: 'view',
			userId,
		});
	});

	const { languageCatalog$: languageCatalog } = useContainerData(
		SlPlaygroundContext,
		SlPlaygroundCodeService,
		['languageCatalog$'],
	);

	return (
		<>
			<SlHelmet
				title={i18n.t('web-playground.list-meta-title')}
				description={i18n.t('web-playground.list-meta-description')}
			/>
			<div className="sl-language-types">
				<div className="sl-language-types__content">
					<h1 className="sl-language-types__title">
						{i18n.t('web-playground.list-title')}
					</h1>
					<p className="sl-language-types__description">
						{i18n.t('web-playground.list-description')}
					</p>
					<h2 className="sl-language-types__choose-text">
						{i18n.t('web-playground.list-choose')}
					</h2>
					<div className="sl-language-types__types-wrapper">
						{languageCatalog?.map((categoryCatalog) => (
							<div
								className="sl-language-types__category-wrapper"
								key={categoryCatalog.category.id}
							>
								<h3 className="sl-language-types__category-name">
									{i18n.t(
										`web-playground.category-name-${categoryCatalog.category.id}`,
									)}
								</h3>
								<div className="sl-language-types__language-wrapper">
									<LanguagesList
										categoryCatalog={categoryCatalog}
									/>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
			<SlFooter />
		</>
	);
}

import { CodeLanguages } from '../../api/private/global.interface';
import { ISlLoginResponse } from '../../login/private/global.interface';
import { IFlashMessageTypes } from '../../shared/public/SlFlashMessage/SlFlashMessage';

export enum SlPlaygroundActionTypes {
	getUserCode = 'getUserCode',
	getTemplate = 'getTemplate',
	saveUserCode = 'saveUserCode',
	compileCode = 'compileCode',
	compileWebCode = 'compileWebCode',
	getDefaultCode = 'getDefaultCode',
	publishCode = 'publishCode',
	continueAfterCodeSaving = 'continueAfterCodeSaving',
	changeEditorMode = 'changeEditorMode',
	makeFreeze = 'makeFreeze',
	getCodeData = 'getCodeData',
	resetContainer = 'resetContainer',
	initApp = 'initApp',
	initPlayground = 'initPlayground',

	onHeaderCompileCode = 'onHeaderCompileCode',
	onHeaderSaveCode = 'onHeaderSaveCode',
	onHeaderPublishCode = 'onHeaderPublishCode',
	onHeaderCloseIcon = 'onHeaderCloseIcon',
	onLanguageChange = 'onLanguageChange',
	onHeaderRenameCode = 'onHeaderRenameCode',
	onModalCompileCode = 'onModalCompileCode',
	toggleConsole = 'toggleConsole',
	openConsole = 'openConsole',
	SlTiyRunCall = 'SlTiyRunCall',
	closeUserMenu = 'closeUserMenu',

	// Initial Events
	initNavigation = 'initNavigation',
	openLoginModal = 'openLoginModal',

	// Mouse Events
	onLogoClick = 'onLogoClick',
	onCourseClick = 'onCourseClick',
	onUserMenuItemClick = 'onUserMenuItemClick',
	onAvatarClick = 'onAvatarClick',
	onUserPhotoClick = 'onUserPhotoClick',
	onStartCourseClick = 'onStartCourseClick',
	onRegisterClick = 'onRegisterClick',
	onLanguageSelectClick = 'onLanguageSelectClick',
}

export interface IError {
	code: number;
	message: string;
	field?: string;
	errorType?: string;
}

export interface IErrorsState extends IError {
	type?: SlPlaygroundActionTypes;
}

export enum IModalTypes {
	CLOSE = 'close',
	SAVE = 'save',
	RUN = 'run',
	RENAME = 'rename',
	PUBLISH = 'publish',
}

export enum IModalStates {
	CLOSE = 'close',
	OPEN = 'open',
}

export interface IModalData {
	type: IModalTypes;
	state: IModalStates;
}

export interface IFlashMessage {
	type: IFlashMessageTypes;
	message: string;
}

export enum UserMenuItemName {
	Profile,
	Settings,
	Help,
	Logout,
}

export enum TrackEventName {
	NavigationClick = 'navigation_click',
	InitNavigation = 'overall_impression',
	OnRunCode = 'overall_run_code',
	InitSignUp = 'signup_popup_impression',
	SingUpCompleted = 'signup_completed',
}

export enum TouchPoint {
	Save = 'save',
	Publish = 'public',
	ChangeName = 'change_name',
	VoteCode = 'vote_code',
}

export enum NavigationClickType {
	Language = 'LANGUAGE',
	Logo = 'LOGO',
	Register = 'REGISTER',
	StartCourse = 'START_COURSE',
}

export enum RequestType {
	Parallel,
	Cancelable,
}

export interface IAction {
	type: ActionType;
	requestType?: RequestType;
	data?: IActionData;
}

export enum ActionType {
	// Callback events
	AuthResponseHandler,

	// Component Events
	OnLanguageTypesMount,

	// Click Events
	OnLanguageItemClick,
	OnPositiveReactionClick,

	// Track Event
	Track,

	// Network Events
	GetLanguagesCatalog,
	GetLanguages,
	UpdatePositiveReaction,

	// Business actions
	TriggerNotificationVisibility,
}

export interface IActionData {
	authResponse?: ISlLoginResponse;
	programLanguage?: string;
	clickType?: CatalogLanguageItemClickType;
	section?: string;
	language?: CodeLanguages;
	touchPoint?: TouchPoint;
	trackData?: {
		eventName: string;
		eventParams: {
			[key: string]: string;
		};
	};
}

export enum CatalogLanguageItemClickType {
	'COMPILER' = 'COMPILER',
	'LANGUAGE' = 'LANGUAGE',
}

import React, { CSSProperties, useMemo } from 'react';
import { IconChevronRightSmall } from '@sololearnorg/sol';
import { Container, AuthService, i18n } from '../../../../symphony';
import { SlPlaygroundContext } from '../../playground-context';
import { Actions } from '../../services/ActionsService/actions';
import { SlPlaygroundCodeService } from '../../services/sl-playground-code.service';
import {
	ActionType,
	CatalogLanguageItemClickType,
	RequestType,
} from '../../global-interface';
import { SlPlaygroundTrackingService } from '../../services/sl-playground-tracking.service';
import { useContainerData } from '../../../../shared/public/SlHooks/useContainerData';
import { LanguageRoutes } from '../../../../shared/public/globalInterfaces/globalInterfaces';
import { LocalizedLink } from '../../../../shared/public/LocalizedLink/LocalizedLink';
import { LanguagesCatalogType } from '../../../../api/public/code-api';

interface ILanguagesListProps {
	categoryCatalog: LanguagesCatalogType extends (infer U)[] ? U : never;
}

export function LanguagesList({ categoryCatalog }: ILanguagesListProps) {
	const authService = Container.take('global', AuthService);
	const { actionsV2$ } = Container.take(SlPlaygroundContext, Actions);
	const { languages$: languages } = useContainerData(
		SlPlaygroundContext,
		SlPlaygroundCodeService,
		['languages$'],
	);
	const userId = Container.take('global', AuthService)
		.getUser()
		?.id.toString();
	const { trackAction$ } = Container.take(
		SlPlaygroundContext,
		SlPlaygroundTrackingService,
	);
	const languagesObj = useMemo(() => {
		const obj = {};

		languages?.forEach((language) => {
			obj[language.key] = language;
		});

		return obj;
	}, [languages]);

	const onItemClick = (language: string, clickType: CatalogLanguageItemClickType) => () => {
		actionsV2$.next({
			type: ActionType.OnLanguageItemClick,
			requestType: RequestType.Parallel,
			data: {
				programLanguage:
					language === LanguageRoutes.JAVASCRIPT
						? ('js' as LanguageRoutes)
						: language,
				section: categoryCatalog.category.name,
				clickType,
			},
		});

		trackAction$.next({
			element: `codeplayground_codes_${language}`,
			action: 'select',
			userId,
		});
	};

	return (
		<>
			{categoryCatalog.languageDetails.map((language) => {
				const languageBoxStyles = {
					borderColor: language.color,
					color: `${language.color}90`,
				} as CSSProperties;

				return (
					<div
						className="sl-language-types__language-item"
						key={language.key}
					>
						<h3
							className="sl-language-types__language-button"
							style={languageBoxStyles}
						>
							<LocalizedLink
								key={language.key}
								onClick={onItemClick(
									language.name,
									CatalogLanguageItemClickType.COMPILER,
								)}
								to={`/compiler-playground/${language.name}`}
							>
								{language.displayName}
								{' '}
								{language.key === 'html'
                                || language.key === 'css'
									? 'Editor'
									: 'Compiler'}
							</LocalizedLink>
						</h3>
						{!!languagesObj?.[language.key]
							&& !authService.isLoggedIn() && (
							<LocalizedLink
								key={language.key}
								onClick={onItemClick(
									language.name,
									CatalogLanguageItemClickType.LANGUAGE,
								)}
								to={`/learn/languages/${
									languagesObj[language.key].alias
								}`}
								className="sl-language-types__language-landing-page-url"
								style={{
									color: language.color,
								}}
							>
								{i18n.t('web-playground.learn-more', {
									language: language.displayName,
								})}
								{' '}
								<IconChevronRightSmall className="sl-language-types__language-landing-page-url-icon" />
							</LocalizedLink>
						)}
					</div>
				);
			})}
		</>
	);
}

import { LanguageRoutes } from '../../../shared/public/globalInterfaces/globalInterfaces';

export const COURSE_URLS = {
	[LanguageRoutes.WEB]: '/learn/courses/html-introduction',
	[LanguageRoutes.CPP]: '/learn/courses/c-plus-plus-introduction',
	[LanguageRoutes.PHP]: '/learning/1059',
	[LanguageRoutes.JAVA]: '/learn/courses/java-introduction',
	[LanguageRoutes.CSharp]: '/learn/courses/c-sharp-introduction',
	[LanguageRoutes.RUBY]: '/learning/1081',
	[LanguageRoutes.NODEJS]: '/learning/1024',
	[LanguageRoutes.C]: '/learn/courses/c-introduction',
	[LanguageRoutes.SWIFT]: '/learning/1075',
	[LanguageRoutes.KOTLIN]: '/learning/1160',
	[LanguageRoutes.PYTHON]: '/learn/courses/python-introduction',
	[LanguageRoutes.JQUERY]: '/learning/1082',
	[LanguageRoutes.GO]: '/learning/1164',
	[LanguageRoutes.R]: '/learning/1147 ',
	[LanguageRoutes.HTML]: '/learn/courses/html-introduction',
	[LanguageRoutes.CSS]: '/learn/courses/css-introduction',
	[LanguageRoutes.JAVASCRIPT]: '/learn/courses/javascript-introduction',
};

import { Observable, of } from 'rxjs';

import { Container, ModalService, Service } from '../../../../symphony';
import { TrackingService } from './tracking.service';
import { SlPlaygroundContext } from '../../playground-context';
import { TouchPoint } from '../../global-interface';
import { ISlLoginResponse } from '../../../../login/private/global.interface';

@Service()
export class CommonService {
	private modalService = Container.take('global', ModalService);

	private trackingService = Container.take(
		SlPlaygroundContext,
		TrackingService,
	);

	public authResponseHandler = (
		authResponse: ISlLoginResponse,
	): Observable<null> => {
		this.trackingService.authSuccess(authResponse);
		this.modalService.close('playgroundLoginModal');
		const touchPoint: TouchPoint = Container.take(
			SlPlaygroundContext,
			'touchPoint',
		);
		if (touchPoint !== TouchPoint.VoteCode) {
			this.modalService.open('playgroundCodeSavingModal');
		}
		return of(null);
	};
}

import { Container, AuthService } from '../../../symphony';

import { SlPlaygroundContext } from '../playground-context';
import { SlPlaygroundCodeService } from '../services/sl-playground-code.service';
import { isNode } from '../../../shared/public/utilsPublic/utilsPublic';
import { IError } from '../global-interface';

export enum ErrorCodes {
	error401 = 401,
	error403 = 403,
	error404 = 404,
	error500 = 500,
	error400 = 400,
}

export type INetworkErrorHandlers = {
	[key in ErrorCodes]?: () => void;
};

const { topLevelDomain$ } = Container.take(
	SlPlaygroundContext,
	SlPlaygroundCodeService,
);

const defaultErrorHandlers: INetworkErrorHandlers = {
	[ErrorCodes.error401]: () => {
		const location = window.location || null;
		Container.take('global', AuthService).logout();

		window.location.href = `${
			topLevelDomain$.value
		}/User/Logout?returnUrl=/users/login?returnUrl=${encodeURI(
			location?.href,
		)}`;
	},
	[ErrorCodes.error403]: () => {
		const location = window.location || null;
		Container.take('global', AuthService).logout();
		window.location.href = `${
			topLevelDomain$.value
		}/User/Logout?returnUrl=/users/login?returnUrl=${encodeURI(
			location?.href,
		)}`;
	},
	[ErrorCodes.error404]: () => {
		window.location.href = `${topLevelDomain$.value}/pagenotfound`;
	},
	[ErrorCodes.error500]: () => {
		window.location.href = `${topLevelDomain$.value}/error`;
	},
};

export function handleError(
	errors: IError[],
	handlers: INetworkErrorHandlers = {},
): void {
	if (isNode()) {
		return;
	}

	if (errors && errors.length) {
		const errorHandlers = { ...defaultErrorHandlers, ...handlers };
		if (typeof errorHandlers?.[errors[0].code] === 'function') {
			errorHandlers[errors[0].code]();
		}
	} else {
		window.location.href = `${topLevelDomain$.value}/error`;
	}
}

import React, { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { IconChevronDownThick } from '@sololearnorg/sol';
import useStyles from 'isomorphic-style-loader/useStyles';
import { Container } from '../../../../../symphony';

import { SlPlaygroundActionTypes } from '../../../global-interface';
import { SlPlaygroundContext } from '../../../playground-context';
import { SlPlaygroundActionsService } from '../../../services/sl-playground-actions.service';

import s from './LanguageSelector.scss';
import { useOutsideClick } from '../../../../../shared/public/SlHooks/useOutsideClick';
import { LanguageNames, LanguageRoutes } from '../../../../../shared/public/globalInterfaces/globalInterfaces';
import { LocalizedLink } from '../../../../../shared/public/LocalizedLink/LocalizedLink';

interface ILanguageSelectorProps {
	languageKey: string;
}

export const LanguageSelector: React.FC<ILanguageSelectorProps> = React.memo(
	({ languageKey }) => {
		useStyles(s);
		const language = useParams()?.language;
		const [isLanguageSelectorOpen, setIsLanguageSelectorOpen] = useState<boolean>(false);
		const languageSelectorRef = useRef(null);

		const handleLanguageSelectClick = () => {
			const { actions$ } = Container.take(
				SlPlaygroundContext,
				SlPlaygroundActionsService,
			);

			setIsLanguageSelectorOpen(!isLanguageSelectorOpen);

			actions$.next({
				type: SlPlaygroundActionTypes.onLanguageSelectClick,
				requestType: 'parallel',
				data: {
					language,
				},
			});
		};

		useOutsideClick(
			languageSelectorRef,
			isLanguageSelectorOpen,
			!isLanguageSelectorOpen,
			() => {
				setIsLanguageSelectorOpen(false);
			},
		);

		return (
			<div
				ref={languageSelectorRef}
				className="playground-navbar__title-container"
			>
				<div
					className="playground-navbar__title-wrapper"
					onClick={handleLanguageSelectClick}
				>
					<h1 className="playground-navbar__title">
						{languageKey === 'WEB'
							? `${LanguageNames[languageKey]} Playground`
							: `${LanguageNames[languageKey]} Online Compiler & Playground`}
					</h1>
					<IconChevronDownThick />
				</div>
				<div
					className="playground-navbar__language-selector"
					style={{
						display: isLanguageSelectorOpen ? 'block' : 'none',
					}}
				>
					{Object.keys(LanguageNames).map(
						(languageNameKey) => languageNameKey !== 'SQL'
                            && language !== LanguageRoutes[languageNameKey] && (
							<LocalizedLink
	key={languageNameKey}
	to={`/compiler-playground/${LanguageRoutes[languageNameKey]}`}
	className="playground-navbar__language-selector-single-language"
	target="_blank"
>
	{LanguageNames[languageNameKey]}
</LocalizedLink>
						),
					)}
				</div>
			</div>
		);
	},
);
